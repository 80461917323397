import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import './Header.css';

class NavigationMenu extends Component {
    state = {
        isNavMenuDisplayed: false
    };

    path;

    constructor(props) {
        super(props);
        this.path = props.path;
    }

    onMobileMenuToggleClick() {
        this.setState({isNavMenuDisplayed: !this.state.isNavMenuDisplayed})
    }

    buildLink(destPath, text, linkClasses) {
        let liClasses = ["menuLink"];
        if (destPath === this.path) {
            liClasses.push("selectedPage")
        } else {
            liClasses.push("menuLinkHover")
        }
        return (
            <Link to={destPath} className={[].concat(linkClasses).join(" ")}><li className={liClasses.join(" ")}><span className="hoverChange">{text}</span></li></Link>
        )
    }

    buildMobileLink(destPath, text) {
        return (
            <Link to={destPath} className="menuSwitchDisplay"><li className="menuLink"><span className="hoverChange">{text}</span></li></Link>
        )
    }

    render() {
        var navMenu;
        var navMenuClass;
        var toggle;
        var toggleClose;

        if (this.state.isNavMenuDisplayed) {
            toggleClose = (
                <div className="toggle">
                    <img className="toggleArrow" src={'/images/UPHMenuCloseToggle.svg'} alt="up arrow"/>
                </div>
            )
        } else {
            toggle = (
                <div className="toggle">
                    <img className="toggleArrow" src={'/images/UPHMenuToggle.svg'} alt="down arrow"/>
                </div>
            );
            navMenuClass = "mobileMenuHide"
        }

        let links = [
            this.buildLink("/", "Home"),
            this.buildLink("/floorplans", "Floor Plans", ["largeLink"]),
            this.buildLink("/galleries", "Galleries"),
            //this.buildLink("/inprogress", "In Progress", ["largeLink"]),
            this.buildLink("/buildingtips", "Building Tips", ["largeLink"]),
            this.buildMobileLink("/process", "Process"),
            this.buildLink("/contact", "Contact")
        ];

        navMenu = (
            <ul className={["navMenu", navMenuClass].join(" ")}>
                {links}
            </ul>
        );

        return (
            <div className="flexNoneIE">
                <div className="mobileMenuToggle" onClick={this.onMobileMenuToggleClick.bind(this)}>
                    {toggleClose}
                    Menu
                    {toggle}
                </div>
                {navMenu}
            </div>
        );
    }
}

export default NavigationMenu;
