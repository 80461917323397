import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NavigationMenu from './NavigationMenu';
import '../App.css';
import './Header.css';

class UrbanPrairieHeader extends Component {
    path;

    constructor(props) {
        super(props);
        this.path = props.path;
    }

    render() {
        return (
            <div className="headerContainer">
                <div className="titleContainer">
                    <Link to="/" className="logoHolder">
                        <img className="logo" src={'https://www.urbanprairiehomes.com/images/UPHLogo.svg'} alt="Urban Prairie Homes logo"/>
                    </Link>
                    <div className="estimatesBox menuSwitchNoDisplay">
                        <p className="estimatesTitle">Free Estimates!</p>
                        <p className="estimatesNumber">701-729-5851</p>
                        <p className="estimatesNumber">701-866-7419</p>
                    </div>
                </div>
                <NavigationMenu path={this.path} />
                <div className="mobileEstimates">FREE ESTIMATES! <span className="breakTogether">Call 701-729-5851 or 701-866-7419</span></div>
            </div>
        );
    }
}

export default UrbanPrairieHeader;
