import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import '../App.css';
import './Process.css';
import Bullet from './Bullet';

class UrbanPrairieProcess extends Component {
    render() {
        return (
            <div className="pageWrapper">
                <Header path={this.props.location.pathname}/>
                <div className="contentWrapper">
                    <div className="fullWidthPhoto">
                        <img src={'/images/wideCrop2_102816_1086WildflowerLn_02.jpg'} alt="UPH two-story, front view; Wildflower Lane"/>
                    </div>
                    <div className="pageContent">
                        <h1>Building Process</h1>
                        <p className="paragraphText">We have laid out an example of a typical timeline below but keep in mind that this is
                            only a guide not an actual schedule. The timeline for your particular project will be dependent on many factors.</p>
                        <div className="buildingTip firstItem">
                            <h2 className="blueHeadingUppercase">Finalize plan</h2>
                        </div>
                        <div className="buildingTip">
                            <h2 className="blueHeading">Month One</h2>
                            <ul className="processList">
                                <li className="processListItem">
                                    <div className="listColumnContainer">
                                        <Bullet/>
                                        <p className="paragraphText">Pull permit</p>
                                    </div>
                                </li>
                                <li className="processListItem">
                                    <div className="listColumnContainer">
                                        <Bullet/>
                                        <p className="paragraphText">Lot scraping and excavation</p>
                                    </div>
                                </li>
                                <li className="processListItem">
                                    <div className="listColumnContainer">
                                        <Bullet/>
                                        <p className="paragraphText">Concrete foundation</p>
                                    </div>
                                    <ul className="processSublist">
                                        <li className="processSublistItem"><span className="blackText paragraphText">Finalize window color (other than white)</span></li>
                                        <li className="processSublistItem"><span className="blackText paragraphText">Finalize soffit color (roof edge)</span></li>
                                        <li className="processSublistItem"><span className="blackText paragraphText">Finalize shingle color</span></li>
                                        <li className="processSublistItem"><span className="blackText paragraphText">Finalize front door and glass</span></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="buildingTip">
                            <h2 className="blueHeading">Month Two</h2>
                            <ul className="processList">
                                <li className="processListItem">
                                    <div className="listColumnContainer">
                                        <Bullet/>
                                        <p className="paragraphText">Framing</p>
                                    </div>
                                    <ul className="processSublist">
                                        <li className="processSublistItem"><span className="blackText paragraphText">Finalize stone</span></li>
                                        <li className="processSublistItem"><span className="blackText paragraphText">Finalize siding colors</span></li>
                                        <li className="processSublistItem"><span className="blackText paragraphText">Finalize interior doors and stain color</span></li>
                                        <li className="processSublistItem"><span className="blackText paragraphText">Decide what to do with entertainment wall</span></li>
                                        <li className="processSublistItem"><span className="blackText paragraphText">Decide on light fixtures for each room</span></li>
                                        <li className="processSublistItem"><span className="blackText paragraphText">Choose appliances and provide specification documents</span></li>
                                        <li className="processSublistItem"><span className="blackText paragraphText">Decide on kitchen layout</span></li>
                                        <li className="processSublistItem"><span className="blackText paragraphText">Choose between painted or stained kitchen cabinets</span></li>
                                        <li className="processSublistItem"><span className="blackText paragraphText">Have an idea of flooring selections</span></li>
                                        <li className="processSublistItem"><span className="blackText paragraphText">Decide on plumbing fixtures (show fixtures most important at this time)</span></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="buildingTip">
                            <h2 className="blueHeading">Month Three</h2>
                            <ul className="processList">
                                <li className="processListItem">
                                    <div className="listColumnContainer">
                                        <Bullet/>
                                        <p className="paragraphText">Roofing</p>
                                    </div>
                                </li>
                                <li className="processListItem">
                                    <div className="listColumnContainer">
                                        <Bullet/>
                                        <p className="paragraphText">Walk through with electrician</p>
                                    </div>
                                    <ul className="processSublist">
                                        <li className="processSublistItem"><span className="blackText paragraphText">Important to have a good idea of what type of lights you want</span></li>
                                    </ul>
                                </li>
                                <li className="processListItem">
                                    <div className="listColumnContainer">
                                        <Bullet/>
                                        <p className="paragraphText">Plumbing rough-in</p>
                                    </div>
                                    <ul className="processSublist">
                                        <li className="processSublistItem"><span className="blackText paragraphText">Need to know if we are going to do a custom shower,
                                            sprinkler stub out, garage water line, water softener, etc. as these may affect
                                            plumbing water line layout</span></li>
                                    </ul>
                                </li>
                                <li className="processListItem">
                                    <div className="listColumnContainer">
                                        <Bullet/>
                                        <p className="paragraphText">Heating rough-in</p>
                                    </div>
                                    <ul className="processSublist">
                                        <li className="processSublistItem"><span className="blackText paragraphText">Determine if garage is finished</span></li>
                                        <li className="processSublistItem"><span className="blackText paragraphText">Make final floor selections</span></li>
                                        <li className="processSublistItem"><span className="blackText paragraphText">Cabinet walk through, finalize cabinets</span></li>
                                        <li className="processSublistItem"><span className="blackText paragraphText">Finalize counter top choices</span></li>
                                        <li className="processSublistItem"><span className="blackText paragraphText">Finalize sinks</span></li>
                                        <li className="processSublistItem"><span className="blackText paragraphText">Finalize plumbing fixtures</span></li>
                                    </ul>
                                </li>
                                <li className="processListItem">
                                    <div className="listColumnContainer">
                                        <Bullet/>
                                        <p className="paragraphText">Insulation and Poly</p>
                                    </div>
                                </li>
                                <li className="processListItem">
                                    <div className="listColumnContainer">
                                        <Bullet/>
                                        <p className="paragraphText">Sheetrock</p>
                                    </div>
                                    <ul className="processSublist">
                                        <li className="processSublistItem"><span className="blackText paragraphText">Finalize and order flooring</span></li>
                                        <li className="processSublistItem"><span className="blackText paragraphText">Finalize and order light fixtures</span></li>
                                        <li className="processSublistItem"><span className="blackText paragraphText">Finalize paint colors</span></li>
                                    </ul>
                                </li>
                                <li className="processListItem">
                                    <div className="listColumnContainer">
                                        <Bullet/>
                                        <p className="paragraphText">Finish tape and texture</p>
                                    </div>
                                </li>
                                <li className="processListItem">
                                    <div className="listColumnContainer">
                                        <Bullet/>
                                        <p className="paragraphText">Paint</p>
                                    </div>
                                </li>
                                <li className="processListItem">
                                    <div className="listColumnContainer">
                                        <Bullet/>
                                        <p className="paragraphText">Cabinet installation</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="buildingTip">
                            <h2 className="blueHeading">Month Four</h2>
                            <ul className="processList">
                                <li className="processListItem">
                                    <div className="listColumnContainer">
                                        <Bullet/>
                                        <p className="paragraphText">Install hard surface flooring</p>
                                    </div>
                                    <ul className="processSublist">
                                        <li className="processSublistItem"><span className="blackText paragraphText">Bathroom</span></li>
                                        <li className="processSublistItem"><span className="blackText paragraphText">Laminate</span></li>
                                    </ul>
                                </li>
                                <li className="processListItem">
                                    <div className="listColumnContainer">
                                        <Bullet/>
                                        <p className="paragraphText">Finish trim work and doors</p>
                                    </div>
                                </li>
                                <li className="processListItem">
                                    <div className="listColumnContainer">
                                        <Bullet/>
                                        <p className="paragraphText">Install light fixtures, can lights and outlets</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="buildingTip">
                            <h2 className="blueHeading">Month Five</h2>
                            <ul className="processList">
                                <li className="processListItem">
                                    <div className="listColumnContainer">
                                        <Bullet/>
                                        <p className="paragraphText">Install hard surface counter tops</p>
                                    </div>
                                </li>
                                <li className="processListItem">
                                    <div className="listColumnContainer">
                                        <Bullet/>
                                        <p className="paragraphText">Install plumbing fixtures</p>
                                    </div>
                                </li>
                                <li className="processListItem">
                                    <div className="listColumnContainer">
                                        <Bullet/>
                                        <p className="paragraphText">Begin final touches</p>
                                    </div>
                                    <ul className="processSublist">
                                        <li className="processSublistItem"><span className="blackText paragraphText">Mirrors</span></li>
                                        <li className="processSublistItem"><span className="blackText paragraphText">Hardware</span></li>
                                    </ul>
                                </li>
                                <li className="processListItem">
                                    <div className="listColumnContainer">
                                        <Bullet/>
                                        <p className="paragraphText">Carpet</p>
                                    </div>
                                </li>
                                <li className="processListItem">
                                    <div className="listColumnContainer">
                                        <Bullet/>
                                        <p className="paragraphText">Appliance delivery</p>
                                    </div>
                                </li>
                                <li className="processListItem">
                                    <div className="listColumnContainer">
                                        <Bullet/>
                                        <p className="paragraphText">Exteriors (can be done at various stages of build)</p>
                                    </div>
                                    <ul className="processSublist">
                                        <li className="processSublistItem"><span className="blackText paragraphText">Concrete flat work</span></li>
                                        <li className="processSublistItem"><span className="blackText paragraphText">Siding</span></li>
                                        <li className="processSublistItem"><span className="blackText paragraphText">Stone work</span></li>
                                    </ul>
                                </li>
                                <li className="processListItem noBottomPadding">
                                    <div className="listColumnContainer">
                                        <Bullet/>
                                        <p className="paragraphText">Clean-up</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default UrbanPrairieProcess;
