class ProgressBuild {
    progressGroups = [
        {
            title: "Johnson Renderings",
            thumbnails: [
                {src: "https://urbanprairie.s3.amazonaws.com/InProgress/Johnson1.png", alt: "Johnson Plan Rendering 1"},
                {src: "https://urbanprairie.s3.amazonaws.com/InProgress/Johnson2.png", alt: "Johnson Plan Rendering 2"},
                {src: "https://urbanprairie.s3.amazonaws.com/InProgress/Johnson3.png", alt: "Johnson Plan Rendering 2"}
            ]
        },
        {
            title: "Johnson Renderings Cropped",
            thumbnails: [
                {src: "https://urbanprairie.s3.amazonaws.com/InProgress/Johnson1Crop.png", alt: "Johnson Plan Rendering Cropped 1"},
                {src: "https://urbanprairie.s3.amazonaws.com/InProgress/Johnson2Crop.png", alt: "Johnson Plan Rendering Cropped 2"},
                {src: "https://urbanprairie.s3.amazonaws.com/InProgress/Johnson3Crop.png", alt: "Johnson Plan Rendering Cropped 3"}
            ]
        }
    ];
}

export default ProgressBuild;