import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import '../App.css';
import './FloorPlans.css';
import { Link } from 'react-router-dom';
import FloorPlan from './FloorPlan';

class UrbanPrairieFloorPlans extends Component {
    render() {
        let floorPlans = new FloorPlan();
        let planCategories = floorPlans.planGroupings.map(g => {
            let innerPlans = g.plans.map(p => {
                return (
                <div className="thumbnailCategoryPlans">
                    <div className="backgroundImage">
                        <img src={p.plan.titleImage.src} alt={p.plan.titleImage.alt}/>
                    </div>
                    <Link to={"/floorplans/" + p.path}>
                        <div className="thumbnailText">
                            <h3 className="thumbnailTitle">{p.plan.title}</h3>
                            <div className="detailsAppearFloorPlans menuSwitchNoDisplay">
                                <ul className="detailsHolder menuSwitchNoDisplay">
                                    <li className="floorPlanDetail menuSwitchNoDisplay">{p.plan.glance.squareFeet} square feet</li>
                                    <li className="floorPlanDetail menuSwitchNoDisplay">{p.plan.glance.bedroomsAbr}BR/{p.plan.glance.bathrooms}BA</li>
                                    <li className="floorPlanDetail menuSwitchNoDisplay">{p.plan.glance.garage} garage</li>
                                </ul>
                            </div>
                        </div>
                    </Link>
                </div>
            )});
            return (
                <div>
                    <h2 className="floorPlansTitleFirst">{g.name}</h2>
                    <div className="thumbnailsContainer">
                        {innerPlans}
                    </div>
                </div>
            )
        });
        return (
            <div className="pageWrapper">
                <Header path={this.props.location.pathname}/>
                <div className="contentWrapper">
                    <div className="fullWidthPhoto">
                        <img src={'/images/wideCrop2_091517_5338_8StW_04.jpg'} alt="UPH open concept living, dining and kitchen area; dark and light kitchen cabinets, barstools, fireplace in living room with natural light from modern style 6 pane glass doors; 8th St W"/>
                    </div>
                    <div className="pageContent">
                        <h1>Unique Floor Plans</h1>
                        <p className="paragraphText">Our homes feature contemporary designs, sleek finishes, artistic details and built-ins. We have many different
                            styles and floor plans to choose from but also offer customization options that set us apart from other companies.
                            We understand that every detail of your dream home should be just right and we are passionate about making that
                            happen.</p>
                        {planCategories}
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default UrbanPrairieFloorPlans;
