import React, { Component } from 'react';
import './ImageCarousel.css';
import { CSSTransitionGroup } from 'react-transition-group'
import ImageCarouselLightbox from "./ImageCarouselLightbox";

class ImageCarousel extends Component {
    srcs;
    tickInterval;
    numImages;

    state = {
        timer: null,
        start: 0,
        hover: false,
        lightboxOpen: false,
        lightboxImageSrc: "",
        lightboxImageIndex: 0

    };

    constructor(props) {
        super(props);
        this.srcs = props.srcs;
        this.tickInterval = props.tickInterval;
        this.numImages = props.numImages;
    }

    componentDidMount() {
        let timer = setInterval(this.tick.bind(this), this.tickInterval);
        this.setState({timer: timer, start: 0})
    }

    nextImage() {
        let newStart = this.state.start;
        if (newStart + 1 === this.srcs.length) {
            newStart = 0
        } else {
            newStart++
        }
        this.setState({start:newStart})
    }

    prevImage() {
        let newStart = this.state.start;
        if (newStart - 1 < 0) {
            newStart = this.srcs.length - 1
        } else {
            newStart--
        }
        this.setState({start:newStart})
    }

    tick() {
        this.nextImage()
    }

    componentWillUnmount() {
        clearInterval(this.state.timer);
    }

    onMouseEnter() {
        if (this.state.timer !== null) {
            clearInterval(this.state.timer);
            this.setState({timer: null})
        }

        this.setState({hover: true})
    }

    onMouseExit() {
        if (this.state.timer === null) {
            let timer = setInterval(this.tick.bind(this), this.tickInterval);
            this.setState({timer: timer, start: 0})
        }
        this.setState({hover: false})
    }

    onImageClick(src, index) {
        this.setState({lightboxOpen: true, lightboxImageSrc: src, lightboxImageIndex: index})
    }

    onLightboxClose() {
        this.setState({lightboxOpen: false})
    }

    onLightboxNext() {
        let newIndex = this.state.lightboxImageIndex + 1;
        newIndex = newIndex % this.srcs.length;
        let newSrc = this.srcs[newIndex];
        this.setState({lightboxImageSrc: newSrc, lightboxImageIndex: newIndex})
        return {src: newSrc, counter: newIndex + 1}
    }

    onLightboxPrev() {
        let newIndex = this.state.lightboxImageIndex - 1;
        if (newIndex < 0) {
            newIndex = this.srcs.length - 1
        }
        let newSrc = this.srcs[newIndex];
        this.setState({lightboxImageSrc: newSrc, lightboxImageIndex: newIndex})
        return {src: newSrc, counter: newIndex + 1}
    }

    render() {
        let displaySrcs = [];
        for (let i = 0; i < Math.min(this.props.srcs.length, this.numImages); i++) {
            displaySrcs[i] = this.srcs[(this.state.start + i) % this.srcs.length]
        }

        const images = displaySrcs.map((src, i) => (
            <div className="carouselImageHolder flexNoneIE" onClick={this.onImageClick.bind(this, src, i)}><img className="carouselImage" key={src} src={src}/></div>
        ));

        let lightbox;
        if (this.state.lightboxOpen) {
            lightbox = (
                <ImageCarouselLightbox
                    onClose={this.onLightboxClose.bind(this)}
                    src={this.state.lightboxImageSrc}
                    onNext={this.onLightboxNext.bind(this)}
                    onPrev={this.onLightboxPrev.bind(this)}
                    counter={this.state.lightboxImageIndex + 1}
                    totalImages={this.srcs.length}
                />
            );
        }

        return (
            <div className="floorPlanImages">
                <img onClick={this.prevImage.bind(this)} className="arrow" src={'/images/UPHLeftArrow.svg'} alt="scroll left arrow"/>
                <div className="imageScrollContainer">
                    <div className="transitionGroupHolder" onMouseEnter={this.onMouseEnter.bind(this)} onMouseLeave={this.onMouseExit.bind(this)}>
                        <CSSTransitionGroup
                            transitionName="carousel"
                            transitionEnterTimeout={1000}
                            transitionLeaveTimeout={1000}>
                            {images}
                        </CSSTransitionGroup>
                    </div>
                </div>
                <img onClick={this.nextImage.bind(this)} className="arrow" src={'/images/UPHRightArrow.svg'} alt="scroll right arrow"/>
                {lightbox}
            </div>
        )
    }
}


export default ImageCarousel;