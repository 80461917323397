import React, { Component } from 'react';
import Modal from 'react-modal';
import ReactSwipeEvents from 'react-swipe-events';

export default class ImageCarouselLightbox extends Component {
    onNext;
    onPrev;
    onClose;
    initialSrc;
    initialCounter;
    totalImages;

    state = {
        src: "",
        counter: 0
    };

    constructor(props) {
        super(props);
        this.onNext = props.onNext;
        this.onPrev = props.onPrev;
        this.onClose = props.onClose;
        this.initialSrc = props.src;
        this.initialCounter = props.counter;
        this.totalImages = props.totalImages;
    }

    componentDidMount() {
        this.setState({src: this.initialSrc, counter: this.initialCounter});
        document.body.classList.toggle('lightboxOpenClass')
    }

    componentWillUnmount() {
        document.body.classList.remove('lightboxOpenClass')
    }

    afterOpenModal() {

    }

    closeModal() {

    }

    next() {
        let next = this.onNext();
        this.setState({src: next.src, counter: next.counter})
    }

    prev() {
        let prev = this.onPrev();
        this.setState({src: prev.src, counter: prev.counter})
    }

    onKeyPress(event) {
        if (event.keyCode === 39) {
            this.next()
        }

        if (event.keyCode === 37) {
            this.prev()
        }
    }

    close() {
        this.onClose();
    }

    render() {
        const {src} = this.props;
        return (
            <Modal className="fullsizeModal"
                isOpen={true}
                onAfterOpen={() => this.myEl && this.myEl.focus()}
                onRequestClose={this.closeModal}
                contentLabel="Example Modal"
                onKeyDown={this.onKeyPress.bind(this)}

            >
                    <ReactSwipeEvents onSwipedLeft={this.next.bind(this)} onSwipedRight={this.prev.bind(this)} className="heightConstraint">
                    <div ref={el => { this.myEl = el; }} tabIndex="-1" onKeyDown={this.onKeyPress.bind(this)} className="fullsizeHolder">
                        <div onClick={this.close.bind(this)} className="closeSymbol">&#9587;</div>

                        <div className="imageConstraints">
                            <img className="mainImage" src={this.state.src}/>
                            <div className="mainImageInfo">
                                <div className="counterHolder">Image {this.state.counter} of {this.totalImages}</div>
                                <div className="arrowHolder">
                                    <img onClick={this.prev.bind(this)} className="arrow" src={'/images/UPHLeftArrow.svg'} alt="previous image arrow"/>
                                    <img onClick={this.next.bind(this)} className="arrow" src={'/images/UPHRightArrow.svg'} alt="next image arrow"/>
                                </div>
                            </div>
                        </div>

                    </div>
                    </ReactSwipeEvents>
            </Modal>
        )
    }
}

