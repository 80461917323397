import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import './InProgress.css';
import '../App.css';
import ProgressBuild from './ProgressBuilds';
import './ImageCarousel.css'
import ImageCarouselLightbox from "./ImageCarouselLightbox";

class UrbanPrairieProgress extends Component {
    state = {
        thumbnailGallery: {},
        lightboxOpen: false,
        lightboxImageSrc: "",
        lightboxGroup: {},
        lightboxImageIndex: 0
    };


    constructor(params) {
        super(params);
    }

    onImageClick(src, group, index) {
        this.setState({lightboxOpen: true, lightboxImageSrc: src, lightboxGroup: group, lightboxImageIndex: index})
    }

    onLightboxClose() {
        this.setState({lightboxOpen: false})
    }

    onLightboxNext() {
        let newIndex = this.state.lightboxImageIndex + 1;
        newIndex = newIndex % this.state.lightboxGroup.thumbnails.length;
        let newSrc = this.state.lightboxGroup.thumbnails[newIndex].src;
        this.setState({lightboxImageSrc: newSrc, lightboxImageIndex: newIndex});
        return {src: newSrc, counter: newIndex + 1}
    }

    onLightboxPrev() {
        let newIndex = this.state.lightboxImageIndex - 1;
        if (newIndex < 0) {
            newIndex = this.state.lightboxGroup.thumbnails.length - 1
        }
        let newSrc = this.state.lightboxGroup.thumbnails[newIndex].src;
        this.setState({lightboxImageSrc: newSrc, lightboxImageIndex: newIndex});
        return {src: newSrc, counter: newIndex + 1}
    }

    render() {
        var lightbox;
        if (this.state.lightboxOpen) {
            lightbox = (
                <ImageCarouselLightbox
                    onNext={this.onLightboxNext.bind(this)}
                    onPrev={this.onLightboxPrev.bind(this)}
                    onClose={this.onLightboxClose.bind(this)}
                    src={this.state.lightboxImageSrc}
                    counter={this.state.lightboxImageIndex + 1}
                    totalImages={this.state.lightboxGroup.thumbnails.length}
                />
            )
        }
        let progressBuilds = new ProgressBuild();
        let progressCategories = progressBuilds.progressGroups.map(g => {
            let innerThumbnails = g.thumbnails.map((p, i) => {
                return (
                    <div className="thumbnailCategoryPlans">
                        <div className="backgroundImage">
                            <img src={p.src} alt={p.alt} onClick={this.onImageClick.bind(this, p.src, g, i)}/>
                        </div>
                    </div>
                )});
                return (
                    <>
                        <h2 className="floorPlansTitleFirst">{g.title}</h2>
                        <div className="thumbnailsContainer">
                            {innerThumbnails}
                        </div>
                    </>
                )
        });

        return (
            <div className="pageWrapper">
                <Header path={this.props.location.pathname}/>
                <div className="fullWidthPhoto">
                    <img src={'/images/wideCrop2_House-40.jpg'} alt="UPH Rambler, front view"/>
                </div>
                <div className="contentWrapper">
                    <div className="pageContent">
                        <h1>Builds In Progress</h1>
                        <p className="paragraphText">We know that it can sometimes be difficult to visualize exactly how your ideas will translate to the finished product. That's
                            why we create detailed renderings and plans as we work together. That way, you can be confident your new home turn out exactly the way you envision.</p>
                        <div>
                            {progressCategories}
                            {lightbox}
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default UrbanPrairieProgress;