import React, { Component } from 'react';
import './Process.css';

class UrbanPrairieBullet extends Component {
    render() {
        return (
            <div className="wheatBullet"><img src={'/images/WheatBullet.svg'} alt="wheat symbol"/></div>
        );
    }
}

export default UrbanPrairieBullet;
