import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import './Contact.css';
import '../App.css';
import axios from 'axios';

class UrbanPrairieContact extends Component {
    constructor(props) {
        super(props);
        this.state = {value: ''};

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        let invalidField = false;
        if (!this.state.name) {
            this.setState({
                invalidName: true
            });
            invalidField = true;
        } else {
            this.setState({
                invalidName: false
            });
        }

        if (!this.state.email) {
            this.setState({
                invalidEmail: true
            });
            invalidField = true;
        } else {
            this.setState({
                invalidEmail: false
            });
        }

        if (!this.state.message) {
            this.setState({
                invalidMessage: true
            });
            invalidField = true;
        } else {
            this.setState({
                invalidMessage: false
            });
        }

        if (invalidField) {
            return
        }

        let postPath = "/api/contact-us";
        let self = this;
        let responseFunction = function(response) {
            self.props.history.push("/contact/confirmation");
            window.scrollTo(0,0)
        };
        responseFunction.bind(this);
        axios
            .post(postPath, {
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                message: this.state.message
            })
            .then(responseFunction)
            .catch(function(error) {
            console.log(error);
        });
    }

    render() {
        var invalidNameField;
        if (this.state.invalidName) {
            invalidNameField=(<div className="invalidAlert">Your name is required.</div>)
        }
        var invalidEmailField;
        if (this.state.invalidEmail) {
            invalidEmailField=(<div className="invalidAlert">A valid email is required.</div>)
        }
        var invalidMessageField;
        if (this.state.invalidMessage) {
            invalidMessageField=(<div className="invalidAlert">A message is required.</div>)
        }

        return (
            <div className="pageWrapper">
                <Header path={this.props.location.pathname}/>
                <div className="contentWrapper">
                    <div className="fullWidthPhoto">
                        <img src={'https://www.urbanprairiehomes.com/images/wideCrop2_102915_2024_12.jpg'} alt="UPH kitchen with white cabinets, tile backsplash in shades of grey, grey island with pendant lights and stainless steel appliances; two-story"/>
                    </div>

                        <div className="containerWhite">
                            <h2>Send us a email or call</h2>
                            <p className="paragraphText">Get in touch with Urban Prairie Homes of Fargo, ND to make your dream home a reality. We have over 22 years of construction experience
                                under sole proprietorship, so you can rest assured your home will be built to last. We hold ourselves to the highest quality standards and offer many customization options
                            while still maintaining competitive prices!</p>
                        <div className="containerWhite">
                        </div>
                            <p className="paragraphText">We try our best to answer calls made during business hours but if you
                                do get our voicemail, leave us a message and we will get back to you as soon as possible!</p>
                            <div className="columnContainerAuto">
                                <div className="columnAuto">
                                    <p className="contactDetailBold">Joe Richards, Builder</p>
                                    <p className="contactDetailExtraSpace">Phone: 701-866-7419</p>
                                    <p className="contactDetailExtraSpace">urbanprairiehomes@gmail.com</p>
                                    <p className="contactDetailBold">Urban Prairie Homes</p>
                                    <p className="contactDetail">1100 19th Ave. N. Suite J. #295</p>
                                    <p className="contactDetail">Fargo, ND 58102</p>
                                </div>
                                <div className="horizontalDivider">
                                    <div className="wheatSymbols">
                                        <img className="wheatSymbol" src={'https://www.urbanprairiehomes.com/images/WheatBullet.svg'} alt="wheat symbol"/>
                                        <img className="wheatSymbol" src={'https://www.urbanprairiehomes.com/images/WheatBullet.svg'} alt="wheat symbol"/>
                                        <img className="wheatSymbol" src={'https://www.urbanprairiehomes.com/images/WheatBullet.svg'} alt="wheat symbol"/>
                                    </div>
                                </div>
                                <div className="columnAuto">
                                    <p className="contactDetailBold">Jenny Schuster, REALTOR&#169;</p>
                                    <p className="contactDetail">Phone: 701-729-5851</p>
                                    <p className="contactDetail">Fax: 701-282-9055</p>
                                    <p className="contactDetailExtraSpace">JennySchuster@ParkCompany.com</p>
                                    <p className="contactDetailBold">PARK CO., REALTORS&#169;</p>
                                    <p className="contactDetail">4170 41st Ave S Ste 102</p>
                                    <p className="contactDetail">Fargo, ND 58104</p>
                                </div>
                            </div>
                            <div className="horizontalDividerPresent">
                                <div className="wheatSymbols">
                                    <img className="wheatSymbol" src={'https://www.urbanprairiehomes.com/images/WheatBullet.svg'} alt="wheat symbol"/>
                                    <img className="wheatSymbol" src={'https://www.urbanprairiehomes.com/images/WheatBullet.svg'} alt="wheat symbol"/>
                                    <img className="wheatSymbol" src={'https://www.urbanprairiehomes.com/images/WheatBullet.svg'} alt="wheat symbol"/>
                                </div>
                            </div>
                            <p className="contactDetailBoldCenter">Business Hours</p>
                            <p className="contactDetailCenter">Monday through Friday</p>
                            <p className="contactDetailCenter">8:00 AM to 6:00 PM</p>
                            <p className="contactDetailBoldCenter">Extended hours by appointment only</p>
                        </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default UrbanPrairieContact;
