import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import '../App.css';
import './ContactConfirmation.css';

class UrbanPrairieContactConfirmation extends Component {
    render() {
        return (
            <div className="pageWrapper">
                <Header path={this.props.location.pathname}/>
                <div className="contentWrapper">
                    <div className="fullWidthPhoto">
                    </div>
                    <div className="pageContent">
                        <h1 className="center">Thanks for contacting Urban Prairie Homes!</h1>
                        <h3 className="subheader">We are excited about the possibility of working together and will get back to you as soon as possible.</h3>
                        <div className="addBottomPadding">
                            <h2 className="center">Other Ways to Get in Touch</h2>
                            <p className="paragraphText center">We try our best to answer calls made during business hours but if you
                                do get our voicemail, leave us a message and we'll return your call as soon as we're able!</p>
                            <div className="columnContainerAuto">
                                <div className="columnAuto">
                                    <p className="contactDetailBold">Joe Richards, Builder</p>
                                    <p className="contactDetailExtraSpace">Phone: 701-866-7419</p>
                                    <p className="contactDetailExtraSpace">urbanprairiehomes@gmail.com</p>
                                    <p className="contactDetailBold">Urban Prairie Homes</p>
                                    <p className="contactDetail">1841 43rd St. N, Suite A</p>
                                    <p className="contactDetail">Fargo, ND 58102</p>
                                </div>
                                <div className="horizontalDivider">
                                    <div className="wheatSymbols">
                                        <img className="wheatSymbol" src={'/images/WheatBullet.svg'} alt="wheat symbol"/>
                                        <img className="wheatSymbol" src={'/images/WheatBullet.svg'} alt="wheat symbol"/>
                                        <img className="wheatSymbol" src={'/images/WheatBullet.svg'} alt="wheat symbol"/>
                                    </div>
                                </div>
                                <div className="columnAuto">
                                    <p className="contactDetailBold">Jenny Schuster, REALTOR&#169;</p>
                                    <p className="contactDetail">Phone: 701-729-5851</p>
                                    <p className="contactDetail">Fax: 701-282-9055</p>
                                    <p className="contactDetailExtraSpace">JennySchuster@ParkCompany.com</p>
                                    <p className="contactDetailBold">PARK CO., REALTORS&#169;</p>
                                    <p className="contactDetail">4170 41st Ave S Ste 102</p>
                                    <p className="contactDetail">Fargo, ND 58104</p>
                                </div>
                            </div>
                            <div className="horizontalDividerPresent">
                                <div className="wheatSymbols">
                                    <img className="wheatSymbol" src={'/images/WheatBullet.svg'} alt="wheat symbol"/>
                                    <img className="wheatSymbol" src={'/images/WheatBullet.svg'} alt="wheat symbol"/>
                                    <img className="wheatSymbol" src={'/images/WheatBullet.svg'} alt="wheat symbol"/>
                                </div>
                            </div>
                            <p className="contactDetailBoldCenter">Business Hours</p>
                            <p className="contactDetailCenter">Monday through Friday</p>
                            <p className="contactDetailCenter">8:00 AM to 6:00 PM</p>
                            <p className="contactDetailBoldCenter">Extended hours by appointment only</p>
                        </div>
                </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default UrbanPrairieContactConfirmation;
