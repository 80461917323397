import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import '../App.css';
import './Galleries.css';
import { Link } from 'react-router-dom';
import GalleryContent from './GalleryContent'

class UrbanPrairieGalleries extends Component {
    render() {
        let galleries = new GalleryContent();
        var galleryCategories = [];
        for (var name in galleries.galleries) {
            let g = galleries.galleries[name];
            galleryCategories.push((
                <div className="thumbnailCategory">
                    <div className="backgroundImage">
                        <img className="wideImage" src={g.titleImage.src} alt={g.titleImage.alt}/>
                    </div>
                    <Link to={"/galleries/" + name}>
                        <div className="thumbnailText">
                            <h3 className="thumbnailTitle">{g.title}</h3>
                            <div className="detailsAppear menuSwitchNoDisplay">
                                <p className="galleryCount menuSwitchNoDisplay">{g.thumbnails.length} Photos</p>
                            </div>
                        </div>
                    </Link>
                </div>
            ))
        }

        return (
            <div className="pageWrapper">
                <Header path={this.props.location.pathname}/>
                <div className="contentWrapper">
                    <div className="fullWidthPhoto">
                        <img src={'/images/wideCrop2_102915_180_33AveW_06.jpg'} alt="UPH living area with fireplace faced in grey tile, built-in storage on either side, bar area to right with wine fridge; 33rd Ave W"/>
                    </div>
                    <div className="pageContent">
                        <h1>Photo Galleries</h1>
                        <p className="paragraphText">This gallery showcases many of the contemporary designs, sleek finishes, artistic finishing and built-ins that
                            are featured in our homes. These homes have all been sold, but give you a wide sampling of our many features. We
                            are currently serving the Fargo/West Fargo area. Call for lot information and details!</p>
                        <p className="lastParagraphText">You can rely on our professionals, who get completely involved in your project and provide great attention to
                            detail to build your dream home.</p>
                        <div className="thumbnailsContainer">
                            {galleryCategories}
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default UrbanPrairieGalleries;
