import React, { Component } from 'react';
import '../App.css';
import './Error.css';
import { Link } from 'react-router-dom';

class UrbanPrairieError extends Component {
    render() {
        return (
            <div className="errorPage">
                <h1>Whoops! <span className="breakTogether">Something went wrong!</span></h1>
                <h2 className="blueHeading">Please click <Link to="/">here</Link> to return to the home page.</h2>
            </div>
        );
    }
}

export default UrbanPrairieError;
