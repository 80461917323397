import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import '../App.css';

class UrbanPrairieFooter extends Component {
    render() {
        return (
            <div className="footerContainer">
                <div className="footerContacts menuSwitchNoDisplay">
                    <div className="footerText">
                        <p className="footerTextLine">Jenny Schuster, PARK CO., REALTORS&#169;</p>
                        <p className="footerTextLine">(701) 729-5851</p>
                        <p className="footerTextLine">jennyschuster@parkcompany.com</p>
                    </div>
                    <div className="footerText">
                        <p className="footerTextLine">Joe Richards, Urban Prairie Homes</p>
                        <p className="footerTextLine">(701) 866-7419</p>
                        <p className="footerTextLine">urbanprairiehomes@gmail.com</p>
                    </div>
                </div>
                <ul className="siteMap">
                    <ul className="firstRow">
                        <li className="siteMapLink">
                            <div className="verticalBarRight">
                                <Link to="/">Home</Link>
                            </div>
                        </li>
                        <li className="siteMapLink">
                            <div className="verticalBarRight">
                                <Link to="/floorplans">Floor Plans</Link>
                            </div>
                        </li>
                        <li className="siteMapLink">
                            <div className="verticalBarRight lastBar">
                                <Link to="/galleries">Galleries</Link>
                            </div>
                        </li>
                    </ul>
                    <ul className="secondRow">
                        <li className="siteMapLink">
                            <div className="verticalBarRight">
                                <Link to="/buildingtips">Building Tips</Link>
                            </div>
                        </li>
                        {/*<li className="siteMapLink">*/}
                            {/*<div className="verticalBarRight">*/}
                                {/*<Link to="/buildingtips">In Progress</Link>*/}
                            {/*</div>*/}
                        {/*</li>*/}
                        <li className="siteMapLink">
                            <div className="verticalBarRight">
                                <Link to="/process">Process</Link>
                            </div>
                        </li>
                        <li className="siteMapLink">
                                <Link to="/contact">Contact</Link>
                        </li>
                    </ul>
                </ul>
                <div className="footerCopyright">
                    <p className="footerTextLine">Copyright &copy; 2015-2020 Urban Prairie Homes. <span className="breakTogether breakSmaller"><span className="breakChunk">Do not copy any content</span> <span className="breakChunk">or images without consent.</span></span></p>
                    <p className="footerTextLine">Urban Prairie Homes is a DBA of AR Drywall Co. <span className="breakTogether">License # ND31847</span></p>
                    <p className="footerTextLine topPadding">Website by <span className="winnovationsLink"><a href="https://w-innovations.com">w-innovations, LLC</a></span></p>
                </div>
                <div className="footerIcons">
                    <div className="holder">
                        <a href="https://www.houzz.com/pro/urbanprairiehomes/urban-prairie-homes">
                            <div className="iconSize">
                                <img src={'/images/Houzz_white.png'} alt="Houzz Logo"/>
                            </div>
                        </a>
                    </div>
                    <div className="holder">
                        <a href="https://www.facebook.com/urbanprairiehomes/">
                            <div className="iconSize">
                                <img src={'/images/FB_white.svg'} alt="Facebook Logo"/>
                            </div>
                        </a>
                    </div>
                    <div className="holder">
                        <a href="https://twitter.com/HomesUrban?cn=YWRkcmVzc19ib29rX2NvbnRhY3RfYWRkZWQ%3D&refsrc=email">
                            <div className="iconSize">
                                <img src={'/images/Twitter_white.svg'} alt="Twitter Logo"/>
                            </div>
                        </a>
                    </div>
                    <div className="holder">
                        <a href="https://www.instagram.com/urbanprairiehomes/?hl=en">
                            <div className="iconSize">
                                <img src={'/images/Instagram_white.svg'} alt="Instagram Logo"/>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default UrbanPrairieFooter;
