import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import ImageCarousel from './ImageCarousel';
import FloorPlan from './FloorPlan';
import '../App.css';
import './FloorPlanDetail.css';
import './ImageCarousel.css';
import { Link } from 'react-router-dom';
import ImageCarouselLightbox from "./ImageCarouselLightbox";

class UrbanPrairieFloorPlanDetail extends Component {
    state = {
        floorPlan: {},
        nextFloorPlanHref: "",
        prevFloorPlanHref: "",
        lightboxOpen: false,
        lightboxImageSrc: "",
        lightboxImageIndex: 0
    };
    

    constructor(params) {
        super(params);
        this.state.floorPlan = params.floorPlan
    }

    componentWillMount() {
        this.setup(this.props)
    }

    componentWillReceiveProps(newProps) {
        this.setup(newProps)
    }

    setup(props) {
        let floorPlans = new FloorPlan();
        let floorPlanName = props.match.params.name;

        let planLinks = [];
        for (var name in floorPlans.plans) {
            planLinks.push(name)
        }
        let index = planLinks.findIndex((element) => floorPlanName === element);
        let nextIndex = index + 1;
        if (nextIndex === planLinks.length) {
            nextIndex = 0
        }

        let prevIndex = index - 1;
        if (prevIndex === -1) {
            prevIndex = planLinks.length - 1
        }
        this.setState(
            {
                floorPlan: floorPlans.plans[floorPlanName],
                nextFloorPlanHref: "/floorplans/" + planLinks[nextIndex],
                prevFloorPlanHref: "/floorplans/" + planLinks[prevIndex]
            }
        )
    }

    onImageClick(src, index) {
        this.setState({lightboxOpen: true, lightboxImageSrc: src, lightboxImageIndex: index})
    }

    onLightboxClose() {
        this.setState({lightboxOpen: false})
    }

    onLightboxNext() {
        let newIndex = this.state.lightboxImageIndex + 1;
        newIndex = newIndex % this.state.floorPlan.floorPlanImages.length;
        let newSrc = this.state.floorPlan.floorPlanImages[newIndex];
        this.setState({lightboxImageSrc: newSrc, lightboxImageIndex: newIndex})
        return {src: newSrc, counter: newIndex + 1}
    }

    onLightboxPrev() {
        let newIndex = this.state.lightboxImageIndex - 1;
        if (newIndex < 0) {
            newIndex = this.state.floorPlan.floorPlanImages.length - 1
        }
        let newSrc = this.state.floorPlan.floorPlanImages[newIndex];
        this.setState({lightboxImageSrc: newSrc, lightboxImageIndex: newIndex})
        return {src: newSrc, counter: newIndex + 1}
    }

    render() {
        var widePhotoClass = ["fullWidthPhoto"];
        var widePhoto;
        if (this.state.floorPlan.hasOwnProperty("wideImage")) {
            widePhoto = (
                <img src={this.state.floorPlan.wideImage.src} alt={this.state.floorPlan.wideImage.alt}/>
            )
        } else {
            widePhotoClass.push("hidden")
        }
        var gallery;
        if (this.state.floorPlan.imageGallery.length > 0) {
            gallery = (
                <div className="containerGrey">
                    <h2>Photos</h2>
                    <ImageCarousel key={this.state.floorPlan.title} srcs={this.state.floorPlan.imageGallery} numImages="9" tickInterval="100000"/>
                </div>
            )
        }
        let floorPlanImages = this.state.floorPlan.floorPlanImages.map ((drawingSrc, i) => {
            var drawingClass = ["drawingSize"];
            if (this.state.floorPlan.floorPlanImages.length - 1 === i) {
                drawingClass.push("lastDrawing")
            }
            return (
                <div className={drawingClass.join(" ")}>
                    <img className="floorPlanDrawings" src={drawingSrc} onClick={this.onImageClick.bind(this, drawingSrc, i)}/>
                </div>
            )
        });

        var lightbox;
        if (this.state.lightboxOpen) {
            lightbox = (
                <ImageCarouselLightbox
                    onNext={this.onLightboxNext.bind(this)}
                    onPrev={this.onLightboxPrev.bind(this)}
                    onClose={this.onLightboxClose.bind(this)}
                    src={this.state.lightboxImageSrc}
                    counter={this.state.lightboxImageIndex + 1}
                    totalImages={this.state.floorPlan.floorPlanImages.length}
                />
            )
        }

        var planDrawingsTitle;
        if (this.state.floorPlan.floorPlanImages.length !== 0) {
            planDrawingsTitle = (
                <h2>Floor Plan</h2>
            )
        } else {
            planDrawingsTitle = (
                <h2 className="noDisplay">Floor Plan</h2>
            )
        }


        return (
            <div className="pageWrapper">
                <Header path={this.props.location.pathname}/>
                <div className="contentWrapper">
                    <div className={widePhotoClass.join(" ")}>
                        {widePhoto}
                    </div>
                    <div className="pageContentWithGrey">
                        <div className="sidePadding">
                            <div className="titleArrows">
                                <Link to={{pathname:this.state.prevFloorPlanHref, }} className="leftArrowTitle">
                                    <img src={'https://www.urbanprairiehomes.com/images/UPHLeftArrow.svg'} alt="left arrow"/>
                                </Link>
                                <h1 className="noBottomPadding">{this.state.floorPlan.title}</h1>
                                <Link to={this.state.nextFloorPlanHref} className="rightArrowTitle">
                                    <img src={'https://www.urbanprairiehomes.com/images/UPHRightArrow.svg'} alt="right arrow"/>
                                </Link>
                            </div>
                            <p className="paragraphText noDisplay">{this.state.floorPlan.description}</p>
                        </div>
                        <div className="containerGrey">
                            <div className="columnContainer">
                                <div className="column">
                                    <h2>At a Glance</h2>
                                    <ul className="detailsHolderColumn">
                                        <li className="detail">Square feet: {this.state.floorPlan.glance.squareFeet}</li>
                                        <li className="detail">Bedrooms: {this.state.floorPlan.glance.bedrooms}</li>
                                        <li className="detail">Bathrooms: {this.state.floorPlan.glance.bathrooms}</li>
                                        <li className="detail">Garage: {this.state.floorPlan.glance.garage}</li>
                                        <li className="detail">Basement: {this.state.floorPlan.glance.basement}</li>
                                        <li className="detail">Walkthrough Video: {this.state.floorPlan.glance.WalkthroughVideo}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="containerWhite">
                            {planDrawingsTitle}
                            <div className="drawingsHolder">
                                {floorPlanImages}
                            </div>
                            {lightbox}
                        </div>
                        {gallery}
                        <h2 className="bottomLinkTitle"><Link to="/floorplans">Back to all Floor Plans</Link></h2>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default UrbanPrairieFloorPlanDetail;
