import React, {Component} from 'react';
import Header from './Header';
import Footer from './Footer';
import '../App.css';
import './Gallery.css';
import {Link} from 'react-router-dom';
import GalleryContent from './GalleryContent';
import './ImageCarousel.css'
import ImageCarouselLightbox from "./ImageCarouselLightbox";

class UrbanPrairieGallery extends Component {
    state = {
        thumbnailGallery: {},
        nextGalleryHref: "",
        prevGalleryHref: "",
        lightboxOpen: false,
        lightboxImageSrc: "",
        lightboxImageIndex: 0
    };


    constructor(params) {
        super(params);
        this.state.thumbnailGallery = params.thumbnailGallery
    }

    componentWillMount() {
        this.setup(this.props)
    }

    componentWillReceiveProps(newProps) {
        this.setup(newProps)
    }

    setup(props) {
        let thumbnailGalleries = new GalleryContent();
        let galleryName = props.match.params.name;

        let galleryLinks = [];
        for (var name in thumbnailGalleries.galleries) {
            galleryLinks.push(name)
        }
        let index = galleryLinks.findIndex((element) => galleryName === element);
        let nextIndex = index + 1;
        if (nextIndex === galleryLinks.length) {
            nextIndex = 0
        }

        let prevIndex = index - 1;
        if (prevIndex === -1) {
            prevIndex = galleryLinks.length - 1
        }
        this.setState(
            {
                thumbnailGallery: thumbnailGalleries.galleries[galleryName],
                nextGalleryHref: "/galleries/" + galleryLinks[nextIndex],
                prevGalleryHref: "/galleries/" + galleryLinks[prevIndex]
            }
        )
    }

    onImageClick(src, index) {
        this.setState({lightboxOpen: true, lightboxImageSrc: src, lightboxImageIndex: index})
    }

    onLightboxClose() {
        this.setState({lightboxOpen: false})
    }

    onLightboxNext() {
        let newIndex = this.state.lightboxImageIndex + 1;
        newIndex = newIndex % this.state.thumbnailGallery.thumbnails.length;
        let newSrc = this.state.thumbnailGallery.thumbnails[newIndex];
        this.setState({lightboxImageSrc: newSrc, lightboxImageIndex: newIndex})
        return {src: newSrc, counter: newIndex + 1}
    }

    onLightboxPrev() {
        let newIndex = this.state.lightboxImageIndex - 1;
        if (newIndex < 0) {
            newIndex = this.state.thumbnailGallery.thumbnails.length - 1
        }
        let newSrc = this.state.thumbnailGallery.thumbnails[newIndex];
        this.setState({lightboxImageSrc: newSrc, lightboxImageIndex: newIndex})
        return {src: newSrc, counter: newIndex + 1}
    }

    render() {
        let images = this.state.thumbnailGallery.thumbnails.map((src, i) => {
            var imgClass = ["thumbnailImage"];
            if (this.state.thumbnailGallery.thumbnails.length - 1 === i) {
                imgClass.push("lastImage")
            }
            return (
                <div className={imgClass.join(" ")}>
                    <img className="subgalleryImage" src={src} onClick={this.onImageClick.bind(this, src, i)}/>
                </div>
            )
        });

        var lightbox;
        if (this.state.lightboxOpen) {
            lightbox = (
                <ImageCarouselLightbox
                    onNext={this.onLightboxNext.bind(this)}
                    onPrev={this.onLightboxPrev.bind(this)}
                    onClose={this.onLightboxClose.bind(this)}
                    src={this.state.lightboxImageSrc}
                    counter={this.state.lightboxImageIndex + 1}
                    totalImages={this.state.thumbnailGallery.thumbnails.length}
                />
            )
        }

        return (
            <div className="pageWrapper">
                <Header path={this.props.location.pathname}/>
                <div className="contentWrapper">
                    <div className="fullWidthPhoto">
                        <img src={this.state.thumbnailGallery.wideImage.src} alt={this.state.thumbnailGallery.wideImage.alt}/>
                    </div>
                    <div className="pageContent">
                        <div className="titleArrowsGallery">
                            <Link to={{pathname: this.state.prevGalleryHref,}} className="leftArrowTitle">
                                <img src={'/images/UPHLeftArrow.svg'} alt="left arrow"/>
                            </Link>
                            <h1 className="noBottomPadding">{this.state.thumbnailGallery.title}</h1>
                            <Link to={this.state.nextGalleryHref} className="rightArrowTitle">
                                <img src={'/images/UPHRightArrow.svg'} alt="right arrow"/>
                            </Link>
                        </div>
                        <div className="thumbnailsContainerGallery">
                            {images}
                        </div>
                        {lightbox}
                        <h2 className="bottomLinkTitle"><Link to="/galleries">Back to all Galleries</Link></h2>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default UrbanPrairieGallery;
