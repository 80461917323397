import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import './Home.css';
import '../App.css';

class UrbanPrairieHome extends Component {
    render() {
        return (
            <div className="pageWrapper">
                <Header path={this.props.location.pathname}/>
                <div className="fullWidthPhoto">
                    <img src={'/images/wideCrop2_House-40.jpg'} alt="UPH Rambler, front view"/>
                </div>
                <div className="contentWrapper">

                    <div className="pageContentWithGrey">
                        <h1 className="sloganLineOne">Affordable <span className="breakTogether">Modern Living</span></h1>
                        <h2 className="sloganLineTwo">Build your Dream Home Today!</h2>
                        <div className="containerWhite">
                            <h2>Unique Floor Plans</h2>
                            <div className="columnContainerHome">
                                <div className="imageContainerLeft mobileNoDisplay">
                                    <img src={'/images/homeCrop_042916_2618_8thW_25.jpg'} alt="UPH open concept living, dining and kitchen area; white cabinets, grey tile backsplash, stainless steel appliances, grey island, pendant lighting over dining area and island, built-ins nad entry feature brick details; 8th St W"/>
                                </div>
                                <div className="textContainer">
                                    <p className="paragraphText">Urban Prairie Homes provides an alternative choice to buyers looking for something fresh
                                        and new. Our homes feature contemporary designs, sleek finishes, artistic details and built-ins.
                                        We have many different styles and floor plans to choose from but also offer customization
                                        options that set us apart from other companies. We understand that every detail of your dream home
                                        should be just right and we are passionate about making that happen. Let us build something for you,
                                        exactly the way you want!</p>
                                    <div className="blueButton">
                                        <p className="buttonText"><Link to="/floorplans">Floor Plans</Link></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="containerGrey">
                            <h2 className="rightText">Quality Craftsmanship</h2>
                            <div className="columnContainerHome">
                                <div className="textContainer">
                                    <p className="paragraphText">With over 25+ years experience in the construction industry, our goal was to create something new and
                                        different. For the past five years Urban Prairie Homes has been dedicated to providing our clients with
                                        the finest craftsmanship at affordable prices. Our builder, Joe Richards, is extremely easy to work with
                                        and committed to delivering exceptional quality. See for yourself as you view some of our
                                        completed projects!</p>
                                    <div className="blueButton">
                                        <p className="buttonText"><Link to="/galleries">Galleries</Link></p>
                                    </div>
                                </div>
                                <div className="imageContainerRight mobileNoDisplay">
                                    <img src={'/images/homeCrop_102915_180_33AveW_01.jpg'} alt="UPH two-story; front view with snow"/>
                                </div>
                            </div>
                        </div>
                        <div className="containerWhite">
                            <h2>Money Saving Tips</h2>
                            <div className="columnContainerHome">
                                <div className="imageContainerLeft mobileNoDisplay">
                                    <img src={'/images/homeCrop_051017_6109Marigold_01.jpg'} alt="UPH two-story; front view"/>
                                </div>
                                <div className="textContainer">
                                    <p className="paragraphText">We have compiled some advice which is sure to come in handy during construction of your dream home. These
                                        simple tips will help you save money and enjoy the home building process!</p>
                                    <div className="blueButton">
                                        <p className="buttonText"><Link to="/buildingtips">Building Tips</Link></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="containerGrey">
                            <h2 className="rightText">Satisfied Customers</h2>
                            <div className="columnContainerHome">
                                <div className="textContainer">
                                    <p className="paragraphText italic">“Urban Prairie Homes has been great to work with.
                                        I love my new house and really like how it stands out
                                        from the others with some of the neat design work
                                        Joe and his team have done. Definitely would
                                        recommend them to anyone looking for a new
                                        house with character of its own.”</p>
                                    <p className="customerName">&mdash;Mark</p>
                                </div>
                                <div className="imageContainerRight mobileNoDisplay">
                                    <img src={'/images/homeCrop_House-31.jpg'} alt="UPH kitchen with white cabinets, grey counter tops, brick backsplash, stainless steel appliances and pendant lighting over island"/>
                                </div>
                            </div>
                        </div>
                        <div className="containerWhite">
                            <h2>Free Estimates!</h2>
                            <div className="columnContainerHome">
                                <div className="imageContainerLeft mobileNoDisplay">
                                    <img src={'/images/homeCrop_092016_2508_14StW_01.jpg'} alt="UPH Rambler; front view"/>
                                </div>
                                <div className="textContainer">
                                    <p className="paragraphText">Take the first step towards your dream home today&mdash;get in touch with Urban Prairie Homes!
                                        We offer FREE, no-obligation estimates and can’t wait to hear about your project.
                                        Customization differentiates us from other companies and our builder, Joe Richards, is
                                        great at listening to clients’ ideas and offering alternatives that meet schedule or
                                        budget constraints when necessary.</p>
                                    <div className="blueButton">
                                        <p className="buttonText"><Link to="/contact">Contact Us</Link></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <Footer/>
            </div>
        );
    }
}

export default UrbanPrairieHome;
