import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import '../App.css';
import './BuildingTips.css';

class UrbanPrairieBuildingTips extends Component {
    render() {
        return (
            <div className="pageWrapper">
                <Header path={this.props.location.pathname}/>
                <div className="contentWrapper">
                    <div className="fullWidthPhoto">
                        <img src={'https://www.urbanprairiehomes.com/images/wideCrop2_052616_3622ParkerPlace-03.jpg'} alt="UPH two-story, front view; Parker Place"/>
                    </div>
                    <div className="pageContent">
                        <h1>Home Building Tips</h1>
                        <p className="paragraphText">Building your dream home is exciting and it's one of the largest investments you'll take on in your lifetime.
                            At Urban Prairie Homes, we want this to be a positive experience. In order to facilitate that, we will walk you through the
                            steps and stay on top of things from the moment we're hired through to the end of construction. </p>
                        <p className="multipleParagraphText">
                            To get you started, we've outlined some general things to keep in mind while going through the building process.
                            For information related specifically to the Urban Prairie Homes construction process follow the link below. </p>
                        <div className="largeBlueButton">
                            <p className="largeButtonText"><Link to="/process">Urban Prairie Homes Process</Link></p>
                        </div>
                        <div className="buildingTip">
                            <h2 className="blueHeading">Get pre-qualified for a mortgage</h2>
                            <p className="paragraphText">Whether building a new home or buying an existing home, this first step is the same. By contacting a lender and
                                providing them with some basic information regarding your debt, income and assets, you can find out the size of
                                mortgage for which you are likely to qualify. Then, before that first shovel of earth is turned, you should make
                                sure you get final mortgage pre-approval. This involves filling out a mortgage application and supplying a lender
                                with the necessary documentation to check your financial background and credit rating so you will know exactly how
                                much you can afford to spend.</p>
                        </div>
                        <div className="buildingTip">
                            <h2 className="blueHeading">Check the reputation of your builder</h2>
                            <div className="columnContainer">
                                <p className="paragraphText textFlexObject">There are many builders to choose from, but not all are created equal. Do a little research to find out which
                                builders have the best reputations. Whether you search for information online or get recommendations from your
                                friends and family, find out whether a builder is respected for doing quality work as well as being punctual.</p>
                                <div className="rightImage menuSwitchNoDisplay">
                                    <img src={"../images/Aspen Rambler/4.jpeg"} alt="UPH two-story; front view"/>
                                </div>
                            </div>
                        </div>
                        <div className="buildingTip">
                            <h2 className="blueHeading">Know your budget</h2>
                            <p className="paragraphText">New home communities list a base price for the homes that they offer. However, this is rarely the actual
                                final cost when building a new home. Be aware that you can add thousands of dollars to the base price of a
                                home very quickly if you get carried away upgrading the standard flooring, cabinetry or lighting. It’s important
                                to know exactly how much you can afford and to budget accordingly.</p>
                        </div>
                        <div className="buildingTip">
                            <h2 className="blueHeading">Build with resale in mind</h2>
                            <div className="columnContainer">
                                <div className="leftImage menuSwitchNoDisplay">
                                    <img src={"../images/Aspen Rambler/1.jpeg"} alt="UPH kitchen with white cabinets, off-white subway tile backsplash, grey island with pendant lights and stainless steel appliances; 43rd St S"/>
                                </div>
                                <p className="paragraphText textFlexObject">No matter how much you love the house that you are building, it’s unlikely that it will be the last home you will
                                    ever own. Knowing that, you should be mindful of its potential resale value. Don’t add so many upgrades that you
                                    overprice your home for the neighborhood. And don’t choose anything too out of the ordinary. Ask yourself if the
                                    features you’re considering installing are likely going to appeal to others.</p>
                            </div>
                        </div>
                        <div className="buildingTip">
                            <h2 className="blueHeading">Know your timeline</h2>
                            <p className="paragraphText">Building a new home usually takes many months and many things must be coordinated during this time frame.
                                If you are already a homeowner, your current home must be sold, you must make decisions regarding your new
                                home and you must arrange a new mortgage. Get an estimate of when the building of your new home will be
                                completed and plan accordingly.</p>
                        </div>
                        <div className="buildingTip">
                            <h2 className="blueHeading">Be prepared for delays</h2>
                            <div className="columnContainer">
                                <p className="paragraphText textFlexObject">No matter what time frame a builder gives you, there is always the possibility of delays. Inclement weather,
                                    shortages of supplies and labor problems can all factor into delaying the completion of your home.
                                    Be aware of this going into the building process and be prepared to be somewhat flexible.</p>
                                <div className="rightImage menuSwitchNoDisplay">
                                    <img src={"../images/Aspen Rambler/2.jpeg"} alt="UPH Rambler; front view with snow"/>
                                </div>
                            </div>
                        </div>
                        <div className="buildingTip">
                            <h2 className="blueHeading">Keep a close watch on progress</h2>
                            <p className="paragraphText">One way to help prevent delays and mix-ups is to stay involved in the building process. If possible, drive by
                                the construction site to keep track of the progress that is being made. You should also check in with your
                                builder on a regular basis.</p>
                        </div>
                        <div className="buildingTip">
                            <h2 className="blueHeading">Avoid making changes</h2>
                            <div className="columnContainer">
                                <div className="leftImage menuSwitchNoDisplay">
                                    <img src={"../images/Aspen Rambler/3.jpeg"} alt="UPH living room with white couch and fireplace insert; 8th St W"/>
                                </div>
                                <p className="paragraphText textFlexObject">Try to avoid making changes to your designs once all of the plans have been completed. It will delay the
                                    completion of your new home and may add considerably to the final cost.</p>
                            </div>
                        </div>
                        <div className="buildingTip">
                            <h2 className="blueHeading">Arrange temporary housing</h2>
                            <p className="paragraphText">Chances are there may be a delay between the time you sell your existing home (or the lease expires on your
                                current rental unit) and the time you move into your new home. If this is the case, you will need to arrange
                                some temporary housing. Realize that you may be living there for several months so make sure it will be both
                                affordable and able to meet your needs.</p>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default UrbanPrairieBuildingTips;
