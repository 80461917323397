import React, { Component } from 'react';
import './App.css';
import { Switch, Route } from 'react-router-dom';
import UrbanPrairieHome from './component/Home';
import UrbanPrairieFloorPlans from './component/FloorPlans';
import UrbanPrairieGalleries from './component/Galleries';
import UrbanPrairieProgress from './component/InProgressStatic';
import UrbanPrairieBuildingTips from './component/BuildingTips';
import UrbanPrairieContact from './component/Contact';
import UrbanPrairieContactConfirmation from './component/ContactConfirmation';
import UrbanPrairieProcess from './component/Process';
import UrbanPrairieGallery from './component/Gallery';
import UrbanPrairieFloorPlanDetail from './component/FloorPlanDetail';
import UrbanPrairieError from './component/Error';


class App extends Component {
  render() {
    return (
        <Switch>
            <Route exact path='/' component={UrbanPrairieHome}/>
            <Route path='/floorplans/:name' component={UrbanPrairieFloorPlanDetail}/>
            <Route path='/floorplans' component={UrbanPrairieFloorPlans}/>
            <Route path='/galleries/:name' component={UrbanPrairieGallery}/>
            <Route path='/galleries' component={UrbanPrairieGalleries}/>
            <Route path='/inprogress' component={UrbanPrairieProgress}/>
            <Route path='/buildingtips' component={UrbanPrairieBuildingTips}/>
            <Route exact path='/contact/confirmation' component={UrbanPrairieContactConfirmation}/>
            <Route path='/contact' component={UrbanPrairieContact}/>
            <Route path='/process' component={UrbanPrairieProcess}/>
            <Route path='/error' component={UrbanPrairieError}/>
        </Switch>
    );
  }
}

export default App;
