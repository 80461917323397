class GalleryContent {
    galleries = {
        Bathrooms: {
            titleImage: {
                src: "https://www.urbanprairiehomes.com/images/Bathrooms/thumbnailCrop_102915_180_33AveW_24.jpg",
                alt: "UPH bathroom with wood cabinets, white counters and double sinks; walk-in shower and free standing tub; 33rd Ave W"
            },
            wideImage: {
                src: "https://www.urbanprairiehomes.com/images/Bathrooms/wideCrop2_102915_180_33AveW_24.jpg",
                alt: "UPH bathroom with wood cabinets, white counters and double sinks; walk-in shower and free standing tub; 33rd Ave W"
            },
            title: "Bathrooms",
            thumbnails: [
                "https://www.urbanprairiehomes.com/images/Bathrooms/New%202-4-19/012119_6314_31StS_53.jpg",
                "https://www.urbanprairiehomes.com/images/Bathrooms/New%202-4-19/012119_6314_31StS_58.jpg",
                "https://www.urbanprairiehomes.com/images/Bathrooms/New%202-4-19/2.jpg",
                "https://www.urbanprairiehomes.com/images/Bathrooms/New%202-4-19/6.jpg",
                "https://www.urbanprairiehomes.com/images/Bathrooms/New%202-4-19/int18.jpg",
                "https://www.urbanprairiehomes.com/images/Bathrooms/042717_6109Marigold_23.jpg",
                "https://www.urbanprairiehomes.com/images/Bathrooms/042717_6109Marigold_38.jpg",
                "https://www.urbanprairiehomes.com/images/Bathrooms/042717_6109Marigold_49.jpg",
                "https://www.urbanprairiehomes.com/images/Bathrooms/042916_2618_8thW_41.jpg",
                "https://www.urbanprairiehomes.com/images/Bathrooms/091517_5338_8StW_20.jpg",
                "https://www.urbanprairiehomes.com/images/Bathrooms/091517_5338_8StW_28.jpg",
                "https://www.urbanprairiehomes.com/images/Bathrooms/092016_2508_14StW_37.jpg",
                "https://www.urbanprairiehomes.com/images/Bathrooms/092016_2508_14StW_43.jpg",
                "https://www.urbanprairiehomes.com/images/Bathrooms/092016_2508_14StW_46.jpg",
                "https://www.urbanprairiehomes.com/images/Bathrooms/102915_180_33AveW_24.jpg",
                "https://www.urbanprairiehomes.com/images/Bathrooms/DSC01241.jpg",
                "https://www.urbanprairiehomes.com/images/Bathrooms/DSC01243.jpg",
                "../images/Dakota Rambler/Home-16.jpg",
                "../images/Dakota Rambler/Home-17.jpg",
                "../images/Dakota Rambler/Home-19.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_37.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_43.jpg",
                "../images/Meadow Rambler/DSC_7807.jpg",
                "../images/Meadow Rambler/DSC_7932.jpg",
                "../images/Meadow Rambler/DSC_7937.JPG",
                "../images/Meadow Rambler/4802 Foxtail ln S Fargo_24.JPG",
                "../images/Prairie Rambler/Home-12.jpg",
                "../images/Prairie Rambler/092016_2508_14StW_39.jpg",
                "../images/Prairie Rambler/092016_2508_14StW_43.jpg",
                "../images/Prairie Rambler/092016_2508_14StW_45.jpg",
                "../images/Prairie 2 story/042717_6109Marigold_40.jpg",
                "../images/Prairie 2 story/102915_180_33AveW_25.jpg",
                "../images/Meadow 2 story/DSC_8340.jpg",
                "../images/Meadow 2 story/DSC_8395.jpg",
                "../images/Meadow 2 story/DSC_8415.jpg",
                "../images/Prairie SOG/4801 Bluebell Loop S Fargo, ND14.jpg",
                "../images/Bluestem SOG/4884_Blue_Bell_Loop__18.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_16.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_18.jpg",
                "../images/Dakota SOG w Loft/4864 Bluebell Loop S (Staged)_16.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__18.jpg",
                "https://www.urbanprairiehomes.com/images/Bathrooms/DSC01251.jpg"                
            ]
        },
        Bedrooms: {
            titleImage: {
                src: "https://www.urbanprairiehomes.com/images/Bedrooms/thumbnailCrop_042717_6109Marigold_35.jpg",
                alt: "UPH bedroom with raised ceiling and walk-in closet; Marigold"
            },
            wideImage: {
                src: "https://www.urbanprairiehomes.com/images/Bedrooms/wideCrop2_042717_6109Marigold_35.jpg",
                alt: "UPH bedroom with raised ceiling and walk-in closet; Marigold"
            },
            title: "Bedrooms",
            thumbnails: [
                "https://www.urbanprairiehomes.com/images/Bedrooms/042717_6109Marigold_35.jpg",
                "https://www.urbanprairiehomes.com/images/Bedrooms/042717_6109Marigold_37.jpg",
                "https://www.urbanprairiehomes.com/images/Bedrooms/091517_5338_8StW_24.jpg",
                "https://www.urbanprairiehomes.com/images/Bedrooms/092016_2508_14StW_40.jpg",
                "https://www.urbanprairiehomes.com/images/Bedrooms/DSC01245.jpg",
                "https://www.urbanprairiehomes.com/images/Bedrooms/DSC01247.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_41.jpg",
                "../images/Dakota 2 story/042916_2618_8thW_48.jpg",
                "../images/Prairie 2 story/042717_6109Marigold_21.jpg",
                "../images/Prairie SOG/4801 Bluebell Loop S Fargo, ND14.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_11.jpg",
                "../images/Bluestem rambler/House-18.jpg"
            ]
        },
        Entryways: {
            titleImage: {
                src: "https://www.urbanprairiehomes.com/images/Entryways/thumbnailCrop_092016_2508_14StW_06.jpg",
                alt: "UPH entryway with abundant natural light; white barn door into room with brick accent wall; 14th St W"
            },
            wideImage: {
                src: "https://www.urbanprairiehomes.com/images/Entryways/wideCrop2_092016_2508_14StW_06.jpg",
                alt: "UPH entryway with abundant natural light; white barn door into room with brick accent wall; 14th St W"
            },
            title: "Entryways",
            thumbnails: [
                "https://www.urbanprairiehomes.com/images/Entryways/New%202-4-19/012119_6314_31StS_11.jpg",
                "https://www.urbanprairiehomes.com/images/Entryways/New%202-4-19/012119_6314_31StS_13.jpg",
                "https://www.urbanprairiehomes.com/images/Entryways/042717_6109Marigold_05.jpg",
                "https://www.urbanprairiehomes.com/images/Entryways/042916_2618_8thW_12.jpg",
                "https://www.urbanprairiehomes.com/images/Entryways/042916_2618_8thW_61.jpg",
                "../images/Aspen Rambler/20_223_47Ave_W_06.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_04.jpg",
                "../images/Prairie Rambler/Home-13.jpg",
                "../images/Prairie 2 story/042717_6109Marigold_06.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_27.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_22.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__13.jpg",
                "https://www.urbanprairiehomes.com/images/Entryways/092016_2508_14StW_06.jpg"
            ]
        },
        Exteriors: {
            titleImage: {
                src: "https://www.urbanprairiehomes.com/images/Slab%20on%20Grade/thumbnailCrop_SlabGrade-ext2.jpg",
                alt: "UPH Slab on Grade rambler with light siding and stone work in shades of tan; angled front view"
            },
            wideImage: {
                src: "https://www.urbanprairiehomes.com/images/Slab%20on%20Grade/wideCrop_SlabGrade-ext2.jpg",
                alt: "UPH Slab on Grade rambler with light siding and stone work in shades of tan; angled front view"
            },
            title: "Exteriors",
            thumbnails: [
                "https://www.urbanprairiehomes.com/images/Exteriors/New%202-4-19/012119_6314_31StS_02.jpg",
                "https://www.urbanprairiehomes.com/images/Exteriors/042916_2618_8thW_02.jpg",
                "https://www.urbanprairiehomes.com/images/Exteriors/042916_2618_8thW_05.jpg",
                "https://www.urbanprairiehomes.com/images/Exteriors/051017_6109Marigold_01.jpg",
                "https://www.urbanprairiehomes.com/images/Exteriors/051017_6109Marigold_03.jpg",
                "https://www.urbanprairiehomes.com/images/Exteriors/052616_3622ParkerPlace%20-03.jpg",
                "https://www.urbanprairiehomes.com/images/Exteriors/052616_3622ParkerPlace%20-05.jpg",
                "https://www.urbanprairiehomes.com/images/Exteriors/061616_5674_43rdStS_01.jpg",
                "https://www.urbanprairiehomes.com/images/Exteriors/092016_2508_14StW_01.jpg",
                "https://www.urbanprairiehomes.com/images/Exteriors/102816_1086WildflowerLn_02.jpg",
                "https://www.urbanprairiehomes.com/images/Exteriors/102915_180_33AveW_01.jpg",
                "https://www.urbanprairiehomes.com/images/Exteriors/102915_2024_01.jpg",
                "https://www.urbanprairiehomes.com/images/Exteriors/DSC06172%20(2).jpg",
                "https://www.urbanprairiehomes.com/images/Exteriors/House-16.jpg",
                "https://www.urbanprairiehomes.com/images/Exteriors/House-40.jpg",
                "../images/Dakota Rambler/Home-35.jpg",
                "../images/Aspen Rambler/061616_5674_43rdStS_01.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_01.jpg",
                "../images/Meadow Rambler/4802 Foxtail ln S Fargo_30.JPG",
                "../images/Prairie Rambler/igk3liJA.jpg",
                "../images/Dakota 2 story/042916_2618_8thW_03.jpg",
                "../images/Prairie 2 story/042717_6109Marigold_01.jpg",
                "../images/Meadow 2 story/4974-39-12-ave-n_6 (1).jpg",
                "../images/Aspen Rambler/061616_5674_43rdStS_01.jpg",
                "../images/Bluestem SOG/1jT0n5NU.jpg",
                "../images/Prairie SOG/4801 Bluebell Loop S Fargo, ND-18.jpg",
                "../images/Prairie SOG w Loft/4864 Bluebell Loop S (Staged)_34.jpg",
                "../images/Dakota SOG w Loft/PWMb6Uuk.jpg",
                "https://www.urbanprairiehomes.com/images/Exteriors/IMG_1361.JPG"
            ]
        },
        KitchenAndDining: {
            titleImage: {
                src: "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/thumbnailCrop_102915_180_33AveW_09.jpg",
                alt: "UPH kitchen with wood cabinets, white counters, dark grey back splash and pendant lighting over island and dining table; 33rd Ave W"
            },
            wideImage: {
                src: "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/wideCrop2_102915_180_33AveW_09.jpg",
                alt: "UPH kitchen with wood cabinets, white counters, dark grey back splash and pendant lighting over island and dining table; 33rd Ave W"
            },
            title: "Kitchen and Dining",
            thumbnails: [
                "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/New%202-4-19/012119_6314_31StS_22.jpg",
                "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/New%202-4-19/012119_6314_31StS_25.jpg",
                "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/New%202-4-19/012119_6314_31StS_32.jpg",
                "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/New%202-4-19/1.jpg",
                "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/New%202-4-19/int2.jpg",
                "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/New%202-4-19/int3.jpg",
                "../images/Dakota Rambler/Home-9.jpg",
                "../images/Dakota Rambler/Home-10.jpg",
                "../images/Dakota Rambler/Home-12.jpg",
                "../images/Dakota Rambler/Home-13.jpg",
                "../images/Dakota Rambler/Home-29.jpg",
                "../images/Aspen Rambler/20_223_47Ave_W_21.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_24.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_26.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_31.jpg",
                "../images/Bluestem rambler/House-32.jpg",
                "../images/Bluestem rambler/House-33.jpg",
                "../images/Bluestem rambler/House-11.jpg",
                "../images/Bluestem rambler/House-13.jpg",
                "../images/Meadow Rambler/DSC_7822.jpg",
                "../images/Meadow Rambler/4802 Foxtail ln S Fargo_15.JPG",
                "../images/Prairie Rambler/Home-16.jpg",
                "../images/Prairie Rambler/Home-18.jpg",
                "../images/Prairie Rambler/Home-19.jpg",
                "../images/Prairie Rambler/Home-22.jpg",
                "../images/Prairie Rambler/092016_2508_14StW_24.jpg",
                "../images/Prairie Rambler/092016_2508_14StW_25.jpg",
                "../images/Prairie Rambler/092016_2508_14StW_30.jpg",
                "../images/Dakota 2 story/102915_2024_17.jpg",
                "../images/Dakota 2 story/102915_2024_12.jpg",
                "../images/Prairie 2 story/042717_6109Marigold_19.jpg",
                "../images/Prairie 2 story/102915_180_33AveW_12.jpg",
                "../images/Prairie 2 story/042717_6109Marigold_21.jpg",
                "../images/Meadow 2 story/DSC_8476.jpg",
                "../images/Meadow 2 story/DSC_8516.jpg",
                "../images/Bluestem SOG/4884_Blue_Bell_Loop__6.jpg",
                "../images/Bluestem SOG/4884_Blue_Bell_Loop__7.jpg",
                "../images/Prairie SOG/4801 Bluebell Loop S Fargo, ND7.jpg",
                "../images/Prairie SOG/4801 Bluebell Loop S Fargo, ND8.jpg",
                "../images/Prairie SOG/4801 Bluebell Loop S Fargo, ND10.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_4.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_6.jpg",
                "../images/Prairie SOG w Loft/4801 Bluebell Loop S Fargo, ND7.jpg",
                "../images/Prairie SOG w Loft/4801 Bluebell Loop S Fargo, ND8.jpg",
                "../images/Prairie SOG w Loft/4801 Bluebell Loop S Fargo, ND10.jpg",
                "../images/Prairie SOG w Loft/4801 Bluebell Loop S Fargo, ND11.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__7.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__9.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__10.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__11.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__12.jpg",
                "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/New%202-4-19/int7.jpg",
                "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/New%202-4-19/int8.jpg",
                "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/New%202-4-19/int9.jpg",
                "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/042717_6109Marigold_15.jpg",
                "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/042717_6109Marigold_17.jpg",
                "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/042717_6109Marigold_18.jpg",
                "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/042916_2618_8thW_39.jpg",
                "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/052616_3622ParkerPlace%20-25.jpg",
                "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/061616_5674_43rdStS_08.jpg",
                "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/061616_5674_43rdStS_14.jpg",
                "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/091517_5338_8StW_14.jpg",
                "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/091517_5338_8StW_15.jpg",
                "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/092016_2508_14StW_24.jpg",
                "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/102915_180_33AveW_09.jpg",
                "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/102915_180_33AveW_11.jpg",
                "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/102915_180_33AveW_17.jpg",
                "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/102915_2024_12.jpg",
                "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/DSC07317.jpg",
                "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/House-30.jpg",
                "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/House-31.jpg",
                "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/DSC01263.jpg",
                "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/IMG_1445use.JPG"
            ]
        },
        SocialSpaces: {
            titleImage: {
                src: "https://www.urbanprairiehomes.com/images/Social%20Spaces/thumbnailCrop_DSC07293.jpg",
                alt: "UPH living room with attached dining and kitchen; fireplace insert with stone work and shelving surround"
            },
            wideImage: {
                src: "https://www.urbanprairiehomes.com/images/Social%20Spaces/wideCrop2_DSC07293.jpg",
                alt: "UPH living room with attached dining and kitchen; fireplace insert with stone work and shelving surround"
            },
            title: "Social Spaces",
            thumbnails: [
                "https://www.urbanprairiehomes.com/images/Social%20Spaces/042717_6109Marigold_12.jpg",
                "https://www.urbanprairiehomes.com/images/Social%20Spaces/042717_6109Marigold_13.jpg",
                "https://www.urbanprairiehomes.com/images/Social%20Spaces/042717_6109Marigold_22.jpg",
                "https://www.urbanprairiehomes.com/images/Social%20Spaces/042717_6109Marigold_43.jpg",
                "https://www.urbanprairiehomes.com/images/Social%20Spaces/042916_2618_8thW_20.jpg",
                "https://www.urbanprairiehomes.com/images/Social%20Spaces/042916_2618_8thW_25.jpg",
                "https://www.urbanprairiehomes.com/images/Social%20Spaces/042916_2618_8thW_32.jpg",
                "https://www.urbanprairiehomes.com/images/Social%20Spaces/091517_5338_8StW_04.jpg",
                "https://www.urbanprairiehomes.com/images/Social%20Spaces/091517_5338_8StW_06.jpg",
                "https://www.urbanprairiehomes.com/images/Social%20Spaces/091517_5338_8StW_07.jpg",
                "https://www.urbanprairiehomes.com/images/Social%20Spaces/091517_5338_8StW_34.jpg",
                "https://www.urbanprairiehomes.com/images/Social%20Spaces/092016_2508_14StW_18.jpg",
                "../images/Dakota Rambler/Home-6.jpg",
                "../images/Dakota Rambler/Home-8.jpg",
                "../images/Aspen Rambler/061616_5674_43rdStS_24.jpg",
                "../images/Aspen Rambler/DSC06188.jpg",
                "../images/Aspen Rambler/IMG_1393.JPG",
                "../images/Bluestem rambler/092016_2508_14StW_16.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_50.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_53.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_54.jpg",
                "../images/Bluestem rambler/House-10.jpg",
                "../images/Bluestem rambler/House-28.jpg",
                "../images/Bluestem rambler/House-30.jpg",
                "../images/Meadow Rambler/DSC_7847.jpg",
                "../images/Meadow Rambler/DSC_7852.jpg",
                "../images/Meadow Rambler/DSC_7877.jpg",
                "../images/Meadow Rambler/4802 Foxtail ln S Fargo_7.JPG",
                "../images/Prairie Rambler/092016_2508_14StW_50.jpg",
                "../images/Prairie Rambler/092016_2508_14StW_51.jpg",
                "../images/Prairie Rambler/092016_2508_14StW_55.jpg",
                "../images/Dakota 2 story/042916_2618_8thW_24.jpg",
                "../images/Dakota 2 story/042916_2618_8thW_27.jpg",
                "../images/Dakota 2 story/042916_2618_8thW_28.jpg",
                "../images/Prairie 2 story/102915_180_33AveW_07.jpg",
                "../images/Prairie 2 story/102915_180_33AveW_19.jpg",
                "../images/Meadow 2 story/DSC_8506.jpg",
                "../images/Prairie SOG/4801 Bluebell Loop S Fargo, ND20.jpg",
                "../images/Prairie SOG/4801 Bluebell Loop S Fargo, ND34.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_20.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_21.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_3.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_5.jpg",
                "../images/Prairie SOG w Loft/4801 Bluebell Loop S Fargo, ND12.jpg",
                "../images/Prairie SOG w Loft/4801 Bluebell Loop S Fargo, ND13.jpg",
                "../images/Prairie SOG w Loft/4801 Bluebell Loop S Fargo, ND29.jpg",
                "../images/Prairie SOG w Loft/4801 Bluebell Loop S Fargo, ND34.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__14.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__3.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__4.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__5.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__6.jpg",
                "https://www.urbanprairiehomes.com/images/Social%20Spaces/092016_2508_14StW_54.jpg",
                "https://www.urbanprairiehomes.com/images/Social%20Spaces/092016_2508_14StW_55.jpg",
                "https://www.urbanprairiehomes.com/images/Kitchen%20and%20Dining/091517_5338_8StW_35.jpg",
                "https://www.urbanprairiehomes.com/images/Social%20Spaces/102915_180_33AveW_06.jpg",
                "https://www.urbanprairiehomes.com/images/Social%20Spaces/102915_180_33AveW_08.jpg",
                "https://www.urbanprairiehomes.com/images/Social%20Spaces/102915_180_33AveW_18.jpg",
                "https://www.urbanprairiehomes.com/images/Social%20Spaces/102915_2024_23.jpg",
                "https://www.urbanprairiehomes.com/images/Social%20Spaces/DSC07286.jpg",
                "https://www.urbanprairiehomes.com/images/Social%20Spaces/DSC07289.jpg",
                "https://www.urbanprairiehomes.com/images/Social%20Spaces/DSC07293.jpg",
                "https://www.urbanprairiehomes.com/images/Social%20Spaces/House-10.jpg",
                "https://www.urbanprairiehomes.com/images/Social%20Spaces/House-11.jpg",
                "https://www.urbanprairiehomes.com/images/Social%20Spaces/DSC01259.jpg",
                "https://www.urbanprairiehomes.com/images/Social%20Spaces/DSC01287.jpg",
                "https://www.urbanprairiehomes.com/images/Social%20Spaces/DSC01305.jpg",
                "https://www.urbanprairiehomes.com/images/Social%20Spaces/IMG_1499.JPG"

            ]
        },
        UniqueDetails: {
            titleImage: {
                src: "https://www.urbanprairiehomes.com/images/Unique%20Details/thumbnailCrop_House-25.jpg",
                alt: "UPH brick accent wall with industrial metal window trim and modern chandelier"
            },
            wideImage: {
                src: "https://www.urbanprairiehomes.com/images/Unique%20Details/thumbnailCrop_House-25.jpg",
                alt: "UPH brick accent wall with industrial metal window trim and modern chandelier"
            },
            title: "Unique Details",
            thumbnails: [
                "https://www.urbanprairiehomes.com/images/Unique%20Details/New%202-4-19/012119_6314_31StS_72.jpg",
                "https://www.urbanprairiehomes.com/images/Unique%20Details/042717_6109Marigold_20.jpg",
                "https://www.urbanprairiehomes.com/images/Unique%20Details/042916_2618_8thW_32.jpg",
                "https://www.urbanprairiehomes.com/images/Unique%20Details/052616_3622ParkerPlace%20-34.jpg",
                "https://www.urbanprairiehomes.com/images/Unique%20Details/DSC07361.jpg",
                "https://www.urbanprairiehomes.com/images/Unique%20Details/DSC07388.jpg",
                "https://www.urbanprairiehomes.com/images/Unique%20Details/DSC07406.jpg",
                "https://www.urbanprairiehomes.com/images/Unique%20Details/House-27.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_07.jpg",
                "../images/Bluestem rambler/House-20.jpg",
                "../images/Bluestem rambler/House-27.jpg",
                "../images/Meadow Rambler/DSC_7792.jpg",
                "../images/Prairie Rambler/092016_2508_14StW_18.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_24.jpg",
                "https://www.urbanprairiehomes.com/images/Unique%20Details/DSC01271.jpg",
                "https://www.urbanprairiehomes.com/images/Unique%20Details/102915_2024_25.jpg"
            ]
        },
        UtilityAndStorage: {
            titleImage: {
                src: "https://www.urbanprairiehomes.com/images/Utility%20and%20Storage/thumbnailCrop_042717_6109Marigold_10.jpg",
                alt: "UPH garage with white walls and concrete floor, wood steps to door"
            },
            wideImage: {
                src: "https://www.urbanprairiehomes.com/images/Utility%20and%20Storage/wideCrop2_042717_6109Marigold_10.jpg",
                alt: "UPH garage with white walls and concrete floor, wood steps to door"
            },
            title: "Utility and Storage",
            thumbnails: [
                "https://www.urbanprairiehomes.com/images/Utility%20and%20Storage/New%202-4-19/012119_6314_31StS_45.jpg",
                "https://www.urbanprairiehomes.com/images/Utility%20and%20Storage/New%202-4-19/ext7.jpg",
                "https://www.urbanprairiehomes.com/images/Utility%20and%20Storage/042717_6109Marigold_10.jpg",
                "https://www.urbanprairiehomes.com/images/Utility%20and%20Storage/042717_6109Marigold_34.jpg",
                "https://www.urbanprairiehomes.com/images/Utility%20and%20Storage/052616_3622ParkerPlace%20-30.jpg",
                "https://www.urbanprairiehomes.com/images/Utility%20and%20Storage/052616_3622ParkerPlace%20-71.jpg",
                "https://www.urbanprairiehomes.com/images/Utility%20and%20Storage/061616_5674_43rdStS_39.jpg",
                "../images/Bluestem rambler/House-38.jpg",
                "../images/Aspen Rambler/House-24.jpg",
                "../images/Aspen Rambler/House-25.jpg",
                "../images/Meadow Rambler/4802 Foxtail ln S Fargo_22.JPG",
                "../images/Prairie 2 story/042717_6109Marigold_43.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_1.jpg",
                "https://www.urbanprairiehomes.com/images/Utility%20and%20Storage/IMG_1435.JPG"
            ]
        },
        UPH1268: {
            titleImage: {
                src: "../images/Dakota Rambler/Home-35.jpg",
                alt: "Dakota Rambler, front view"
            },
            wideImage: {
                src: "../images/Dakota Rambler/Home-35.jpg",
                alt: "Dakota Rambler, front view"
            },
            title: "Dakota Rambler",
            thumbnails: [
                "../images/Dakota Rambler/Home-6.jpg",
                "../images/Dakota Rambler/Home-8.jpg",
                "../images/Dakota Rambler/Home-9.jpg",
                "../images/Dakota Rambler/Home-10.jpg",
                "../images/Dakota Rambler/Home-12.jpg",
                "../images/Dakota Rambler/Home-13.jpg",
                "../images/Dakota Rambler/Home-16.jpg",
                "../images/Dakota Rambler/Home-17.jpg",
                "../images/Dakota Rambler/Home-19.jpg",
                "../images/Dakota Rambler/Home-29.jpg"
            ]
        },
        UPH1826: {
            titleImage: {
                src: "../images/Aspen Rambler/061616_5674_43rdStS_01.jpg",
                alt: "Aspen Rambler, front view"
            },
            wideImage: {
                src: "../images/Aspen Rambler/061616_5674_43rdStS_01.jpg",
                alt: "Aspen Rambler, front view"
            },
            title: "Aspen Rambler",
            thumbnails: [
                "../images/Aspen Rambler/20_223_47Ave_W_21.jpg",
                "../images/Aspen Rambler/20_223_47Ave_W_06.jpg",
                "../images/Aspen Rambler/061616_5674_43rdStS_24.jpg",
                "../images/Aspen Rambler/DSC06188.jpg",
                "../images/Aspen Rambler/IMG_1393.JPG"
            ]
        },
        UPH2028: {
            titleImage: {
                src: "../images/Bluestem rambler/092016_2508_14StW_01.jpg",
                alt: "Bluestem Rambler, front view"
            },
            wideImage: {
                src: "../images/Bluestem rambler/092016_2508_14StW_01.jpg",
                alt: "Bluestem Rambler, front view"
            },
            title: "Bluestem Rambler",
            thumbnails: [
                "../images/Bluestem rambler/092016_2508_14StW_04.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_07.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_16.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_24.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_26.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_31.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_37.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_41.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_43.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_50.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_53.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_54.jpg",
                "../images/Bluestem rambler/House-10.jpg",
                "../images/Bluestem rambler/House-11.jpg",
                "../images/Bluestem rambler/House-13.jpg",
                "../images/Bluestem rambler/House-18.jpg",
                "../images/Bluestem rambler/House-20.jpg",
                "../images/Bluestem rambler/House-27.jpg",
                "../images/Bluestem rambler/House-28.jpg",
                "../images/Bluestem rambler/House-30.jpg",
                "../images/Bluestem rambler/House-32.jpg",
                "../images/Bluestem rambler/House-33.jpg",
                "../images/Bluestem rambler/House-38.jpg"
            ]
        },
        UPHSlab: {
            titleImage: {
                src: "../images/Meadow Rambler/4802 Foxtail ln S Fargo_30.JPG",
                alt: "Meadow Rambler; front view"
            },
            wideImage: {
                src: "../images/Meadow Rambler/4802 Foxtail ln S Fargo_30.JPG",
                alt: "Meadow Rambler; front view"
            },
            title: "Meadow Rambler",
            thumbnails: [
                "../images/Meadow Rambler/4802 Foxtail ln S Fargo_7.JPG",
                "../images/Meadow Rambler/4802 Foxtail ln S Fargo_22.JPG",
                "../images/Meadow Rambler/4802 Foxtail ln S Fargo_24.JPG",
                "../images/Meadow Rambler/DSC_7792.jpg",
                "../images/Meadow Rambler/DSC_7822.jpg",
                "../images/Meadow Rambler/4802 Foxtail ln S Fargo_15.JPG",
                "../images/Meadow Rambler/DSC_7847.jpg",
                "../images/Meadow Rambler/DSC_7852.jpg",
                "../images/Meadow Rambler/DSC_7877.jpg",
                "../images/Meadow Rambler/DSC_7807.jpg",
                "../images/Meadow Rambler/DSC_7932.jpg",
                "../images/Meadow Rambler/DSC_7937.JPG"
            ]
        },
        Prairie_Rambler: {
            titleImage: {
                src: "../images/Prairie Rambler/4812- 6150 edited (002).jpg",
                alt: "Prairie Rambler, front view"
            },
            wideImage: {
                src: "../images/Prairie Rambler/4812- 6150 edited (002).jpg",
                alt: "Prairie Rambler, front view"
            },
            title: "Prairie Rambler",
            thumbnails: [
                "../images/Prairie Rambler/092016_2508_14StW_18.jpg",
                "../images/Prairie Rambler/092016_2508_14StW_24.jpg",
                "../images/Prairie Rambler/092016_2508_14StW_25.jpg",
                "../images/Prairie Rambler/092016_2508_14StW_30.jpg",
                "../images/Prairie Rambler/092016_2508_14StW_41.jpg",
                "../images/Prairie Rambler/092016_2508_14StW_39.jpg",
                "../images/Prairie Rambler/092016_2508_14StW_43.jpg",
                "../images/Prairie Rambler/092016_2508_14StW_45.jpg",
                "../images/Prairie Rambler/092016_2508_14StW_50.jpg",
                "../images/Prairie Rambler/092016_2508_14StW_51.jpg",
                "../images/Prairie Rambler/092016_2508_14StW_55.jpg",
                "../images/Prairie Rambler/Home-12.jpg",
                "../images/Prairie Rambler/Home-13.jpg",
                "../images/Prairie Rambler/Home-16.jpg",
                "../images/Prairie Rambler/Home-18.jpg",
                "../images/Prairie Rambler/Home-19.jpg",
                "../images/Prairie Rambler/Home-22.jpg"
            ]
        },
        UPH2274: {
            titleImage: {
                src: "../images/Dakota 2 story/042916_2618_8thW_03.jpg",
                alt: "Dakota Two Story, front view"
            },
            wideImage: {
                src: "../images/Dakota 2 story/042916_2618_8thW_03.jpg",
                alt: "Dakota Two Story, front view"
            },
            title: "Dakota Two Story",
            thumbnails: [
                "../images/Dakota 2 story/042916_2618_8thW_24.jpg",
                "../images/Dakota 2 story/042916_2618_8thW_27.jpg",
                "../images/Dakota 2 story/042916_2618_8thW_28.jpg",
                "../images/Dakota 2 story/042916_2618_8thW_48.jpg",
                "../images/Dakota 2 story/042916_2618_8thW_61.jpg",
                "../images/Dakota 2 story/102915_2024_17.jpg",
                "../images/Dakota 2 story/102915_2024_12.jpg"
            ]
        },
        UPH2660: {
            titleImage: {
                src: "../images/Prairie 2 story/042717_6109Marigold_01.jpg",
                alt: "Prairie Two Story, front view"
            },
            wideImage: {
                src: "../images/Prairie 2 story/042717_6109Marigold_01.jpg",
                alt: "Prairie Two Story, front view"
            },
            title: "Prairie Two Story",
            thumbnails: [
                "../images/Prairie 2 story/042717_6109Marigold_06.jpg",
                "../images/Prairie 2 story/042717_6109Marigold_19.jpg",
                "../images/Prairie 2 story/102915_180_33AveW_12.jpg",
                "../images/Prairie 2 story/042717_6109Marigold_21.jpg",
                "../images/Prairie 2 story/042717_6109Marigold_26.jpg",
                "../images/Prairie 2 story/042717_6109Marigold_43.jpg",
                "../images/Prairie 2 story/102915_180_33AveW_07.jpg",
                "../images/Prairie 2 story/102915_180_33AveW_19.jpg",
                "../images/Prairie 2 story/042717_6109Marigold_40.jpg",
                "../images/Prairie 2 story/102915_180_33AveW_25.jpg"
            ]
        },
        UPH3254: {
            titleImage: {
                src: "../images/Meadow 2 story/4974-39-12-ave-n_6 (1).jpg",
                alt: "Meadow Two Story, front view"
            },
            wideImage: {
                src: "../images/Meadow 2 story/4974-39-12-ave-n_6 (1).jpg",
                alt: "Meadow Two Story, front view"
            },
            title: "Meadow Two Story",
            thumbnails: [
                "../images/Meadow 2 story/DSC_8340.jpg",
                "../images/Meadow 2 story/DSC_8395.jpg",
                "../images/Meadow 2 story/DSC_8415.jpg",
                "../images/Meadow 2 story/DSC_8506.jpg",
                "../images/Meadow 2 story/DSC_8476.jpg",
                "../images/Meadow 2 story/DSC_8516.jpg"
            ]
        },
        UPH3Level: {
            titleImage: {
                src: "../images/Aspen Rambler/061616_5674_43rdStS_01.jpg",
                alt: "Aspen Slab on Grade, front view"
            },
            wideImage: {
                src: "../images/Aspen Rambler/061616_5674_43rdStS_01.jpg",
                alt: "Aspen Slab on Grade, front view"
            },
            title: "Aspen Slab on Grade",
            thumbnails: [
                "../images/Aspen Rambler/20_223_47Ave_W_21.jpg",
                "../images/Aspen Rambler/20_223_47Ave_W_06.jpg",
                "../images/Aspen Rambler/061616_5674_43rdStS_24.jpg",
                "../images/Aspen Rambler/DSC06188.jpg",
                "../images/Aspen Rambler/IMG_1393.JPG"
            ]
        },
        Bluestem_SOG: {
            titleImage: {
                src: "../images/Bluestem SOG/1jT0n5NU.jpg",
                alt: "Bluestem Slab on Grade, front view"
            },
            wideImage: {
                src: "../images/Bluestem SOG/1jT0n5NU.jpg",
                alt: "Bluestem Slab on Grade, front view"
            },
            title: "Bluestem Slab on Grade",
            thumbnails: [
                "../images/Bluestem SOG/4884_Blue_Bell_Loop__6.jpg",
                "../images/Bluestem SOG/4884_Blue_Bell_Loop__7.jpg",
                "../images/Bluestem SOG/4884_Blue_Bell_Loop__18.jpg"
            ]
        },
        Meadow_SOG: {
            titleImage: {
                src: "../images/Meadow SOG/DSC_7989.JPG",
                alt: "Meadow Slab on Grade, front view"
            },
            wideImage: {
                src: "../images/Meadow SOG/DSC_7989.JPG",
                alt: "Meadow Slab on Grade, front view"
            },
            title: "Meadow Slab on Grade",
            thumbnails: [
                "../images/Meadow SOG/Home-8.jpg",
                "../images/Meadow SOG/Home-9.jpg",
                "../images/Meadow SOG/Home-10.jpg",
                "../images/Meadow SOG/Home-12.jpg"
            ]
        },
        Prairie_SOG: {
            titleImage: {
                src: "../images/Prairie SOG/4801 Edited.jpg",
                alt: "Prairie Slab on Grade, front view"
            },
            wideImage: {
                src: "../images/Prairie SOG/4801 Edited.jpg",
                alt: "Prairie Slab on Grade, front view"
            },
            title: "Prairie Slab on Grade",
            thumbnails: [
                "../images/Prairie SOG/4801 Bluebell Loop S Fargo, ND7.jpg",
                "../images/Prairie SOG/4801 Bluebell Loop S Fargo, ND8.jpg",
                "../images/Prairie SOG/4801 Bluebell Loop S Fargo, ND10.jpg",
                "../images/Prairie SOG/4801 Bluebell Loop S Fargo, ND14.jpg",
                "../images/Prairie SOG/4801 Bluebell Loop S Fargo, ND19.jpg",
                "../images/Prairie SOG/4801 Bluebell Loop S Fargo, ND20.jpg",
                "../images/Prairie SOG/4801 Bluebell Loop S Fargo, ND34.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_1.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_3.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_5.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_4.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_6.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_11.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_16.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_18.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_20.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_21.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_22.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_24.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_27.jpg"
            ]
        },
        Prairie_SOG_loft: {
            titleImage: {
                src: "../images/Prairie SOG w Loft/4864 Bluebell Loop S (Staged)_34.jpg",
                alt: "Prairie Slab on Grade With Loft, front view"
            },
            wideImage: {
                src: "../images/Prairie SOG w Loft/4864 Bluebell Loop S (Staged)_34.jpg",
                alt: "Prairie Slab on Grade With Loft, front view"
            },
            title: "Prairie Slab on Grade With Loft",
            thumbnails: [
                "../images/Prairie SOG w Loft/4801 Bluebell Loop S Fargo, ND7.jpg",
                "../images/Prairie SOG w Loft/4801 Bluebell Loop S Fargo, ND8.jpg",
                "../images/Prairie SOG w Loft/4801 Bluebell Loop S Fargo, ND10.jpg",
                "../images/Prairie SOG w Loft/4801 Bluebell Loop S Fargo, ND11.jpg",
                "../images/Prairie SOG w Loft/4801 Bluebell Loop S Fargo, ND12.jpg",
                "../images/Prairie SOG w Loft/4801 Bluebell Loop S Fargo, ND13.jpg",
                "../images/Prairie SOG w Loft/4801 Bluebell Loop S Fargo, ND29.jpg",
                "../images/Prairie SOG w Loft/4801 Bluebell Loop S Fargo, ND34.jpg"
            ]
        },
        Dakota_SOG_loft: {
            titleImage: {
                src: "../images/Dakota SOG w Loft/PWMb6Uuk.jpg",
                alt: "Dakota Slab on Grade With Loft, front view"
            },
            wideImage: {
                src: "../images/Dakota SOG w Loft/PWMb6Uuk.jpg",
                alt: "Dakota Slab on Grade With Loft, front view"
            },
            title: "Dakota Slab on Grade With Loft",
            thumbnails: [
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__1.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__3.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__4.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__5.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__6.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__7.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__9.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__10.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__11.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__12.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__13.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__14.jpg",
                "../images/Dakota SOG w Loft/4864 Bluebell Loop S (Staged)_16.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__18.jpg"
            ]
        }
    };
}

export default GalleryContent;

