import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import '../App.css';
class FloorPlan {
    plans = {
        
        UPH1268: {
            titleImage: {
                src: "../images/Dakota Rambler/Home-35.jpg",
                alt: "Dakota Rambler, front view"
            },
            wideImage: {
                src: "../images/Dakota Rambler/Home-35.jpg",
                alt: "Dakota Rambler, front view"
            },
            title: "Dakota Rambler",
            description: "This 1650 sq. ft. rambler is perfect for a .... featuring a ... and unique details. As with all Urban Prairie Homes it can be customized to your specifications so that you are truly able to build your dream home!",
            glance:
                {
                    squareFeet: "1650",
                    bedrooms: "4 with option for 5",
                    bedroomsAbr: "4-5",
                    bathrooms: "3",
                    garage: "2 or 3 stall",
                    basement: "Finished",
                    WalkthroughVideo: "Call For Details"
                }
            ,
            floorPlanImages: [
                "../images/Basement Dakota Rambler.PNG",
                "../images/Foundation.PNG"
            ],
            imageGallery: [
                "../images/Dakota Rambler/Home-6.jpg",
                "../images/Dakota Rambler/Home-8.jpg",
                "../images/Dakota Rambler/Home-9.jpg",
                "../images/Dakota Rambler/Home-10.jpg",
                "../images/Dakota Rambler/Home-12.jpg",
                "../images/Dakota Rambler/Home-13.jpg",
                "../images/Dakota Rambler/Home-16.jpg",
                "../images/Dakota Rambler/Home-17.jpg",
                "../images/Dakota Rambler/Home-19.jpg",
                "../images/Dakota Rambler/Home-29.jpg"
            ]
        },
        UPH1295: {
            titleImage: {
                src: "../images/Meadow Rambler/4802 Foxtail ln S Fargo_30.JPG",
                alt: "blue background"
            },
            wideImage: {
                src: "../images/Meadow Rambler/4802 Foxtail ln S Fargo_30.JPG",
                alt: "blue background"
            },
            title: "Meadow Rambler",
            description: "This 1721 sq. ft. rambler is perfect for a .... featuring a ... and unique details. As with all Urban Prairie Homes it can be customized to your specifications so that you are truly able to build your dream home!",
            glance:
                {
                    squareFeet: "1721",
                    bedrooms: "5",
                    bedroomsAbr: "5",
                    bathrooms: "4",
                    garage: "2 or 3 stall",
                    basement: "finished",
                WalkthroughVideo: "Call For Details"
                }
            ,
            floorPlanImages: [
                "../images/Main Floor Meadow Rambler.PNG",
                "../images/Basement Meadow Rambler.PNG"
            ],
            imageGallery: [
                "../images/Meadow Rambler/4802 Foxtail ln S Fargo_7.JPG",
                "../images/Meadow Rambler/4802 Foxtail ln S Fargo_15.JPG",
                "../images/Meadow Rambler/4802 Foxtail ln S Fargo_22.JPG",
                "../images/Meadow Rambler/4802 Foxtail ln S Fargo_24.JPG",
                "../images/Meadow Rambler/DSC_7792.jpg",
                "../images/Meadow Rambler/DSC_7807.jpg",
                "../images/Meadow Rambler/DSC_7822.jpg",
                "../images/Meadow Rambler/DSC_7847.jpg",
                "../images/Meadow Rambler/DSC_7852.jpg",
                "../images/Meadow Rambler/DSC_7877.jpg",
                "../images/Meadow Rambler/DSC_7932.jpg",
                "../images/Meadow Rambler/DSC_7937.JPG"
            ]
        },
        UPH1826: {
            titleImage: {
                src: "../images/Aspen Rambler/rLj73GVo.jpg",
                alt: "Aspen Rambler, front view"
            },
            wideImage: {
                src: "../images/Aspen Rambler/rLj73GVo.jpg",
                alt: "Aspen Rambler, front view"
            },
            title: "Aspen Rambler",
            description: "This 1926 sq. ft. rambler is perfect for a .... featuring a ... and unique details. As with all Urban Prairie Homes it can be customized to your specifications so that you are truly able to build your dream home!",
            glance:
                {
                    squareFeet: "1926",
                    bedrooms: "5",
                    bedroomsAbr: "5",
                    bathrooms: "4",
                    garage: "3 stall",
                    basement: "Finished",
                WalkthroughVideo: "Call For Details"
                }
            ,
            floorPlanImages: [
                "../images/Main FLoor Aspen Rambler.PNG",
                "../images/Basement Aspen Rambler.PNG"
            ],
            imageGallery: [
                "../images/Aspen Rambler/20_223_47Ave_W_21.jpg",
                "../images/Aspen Rambler/20_223_47Ave_W_06.jpg",
                "../images/Aspen Rambler/061616_5674_43rdStS_24.jpg",
                "../images/Aspen Rambler/DSC06188.jpg",
                "../images/Aspen Rambler/IMG_1393.JPG"
            ]
        },
        UPH2028: {
            titleImage: {
                src: "../images/Bluestem rambler/092016_2508_14StW_01.jpg",
                alt: "Bluestem Rambler, front view"
            },
            wideImage: {
                src: "../images/Bluestem rambler/092016_2508_14StW_01.jpg",
                alt: "Bluestem Rambler, front view"
            },
            title: "Bluestem Rambler",
            description: "This 2150 sq. ft. rambler is perfect for a .... featuring a ... and unique details. As with all Urban Prairie Homes it can be customized to your specifications so that you are truly able to build your dream home!",
            glance:
                {
                    squareFeet: "2150",
                    bedrooms: "4 with the option of 5",
                    bedroomsAbr: "4-5",
                    bathrooms: "3",
                    garage: "3 stall",
                    basement: "Finished",
                WalkthroughVideo: "Call For Details"
                }
            ,
            floorPlanImages: [
                "../images/Main floor BlueStem Rambler.PNG",
                "../images/Foundation BLuestem Rambler.PNG"
            ],
            imageGallery: [
                "../images/Bluestem rambler/092016_2508_14StW_04.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_07.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_16.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_24.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_26.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_31.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_37.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_41.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_43.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_50.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_53.jpg",
                "../images/Bluestem rambler/092016_2508_14StW_54.jpg",
                "../images/Bluestem rambler/House-10.jpg",
                "../images/Bluestem rambler/House-11.jpg",
                "../images/Bluestem rambler/House-13.jpg",
                "../images/Bluestem rambler/House-18.jpg",
                "../images/Bluestem rambler/House-20.jpg",
                "../images/Bluestem rambler/House-27.jpg",
                "../images/Bluestem rambler/House-28.jpg",
                "../images/Bluestem rambler/House-30.jpg",
                "../images/Bluestem rambler/House-32.jpg",
                "../images/Bluestem rambler/House-33.jpg",
                "../images/Bluestem rambler/House-38.jpg"
            ]
        },
        UPHSlab: {
        titleImage: {
                src: "../images/Prairie Rambler/4812- 6150 edited (002).jpg",
            alt: "Prairie Rambler with light siding and stone work in shades of tan; front view"
        },
        wideImage: {
            src: "../images/Prairie Rambler/4812- 6150 edited (002).jpg",
            alt: "Prairie Rambler with light siding and stone work in shades of tan; front view"
        },
        title: "Prairie Rambler",
        description: "This 2132 sq. ft. rambler is perfect for a .... featuring a ... and unique details. As with all Urban Prairie Homes it can be customized to your specifications so that you are truly able to build your dream home!",
        glance:
            {
                squareFeet: "2132",
                bedrooms: "5 with option for 6",
                bedroomsAbr: "5-6",
                bathrooms: "4",
                garage: "3 stall",
                basement: "Finished ",
            WalkthroughVideo: "Call For Details"
            }
        ,
        floorPlanImages: [
            "../images/Main floor Prairie Rambler.PNG",
            "../images/Foundation Praire rambler.PNG"
        ],
        imageGallery: [
            "../images/Prairie Rambler/092016_2508_14StW_18.jpg",
            "../images/Prairie Rambler/092016_2508_14StW_24.jpg",
            "../images/Prairie Rambler/092016_2508_14StW_25.jpg",
            "../images/Prairie Rambler/092016_2508_14StW_30.jpg",
            "../images/Prairie Rambler/092016_2508_14StW_39.jpg",
            "../images/Prairie Rambler/092016_2508_14StW_41.jpg",
            "../images/Prairie Rambler/092016_2508_14StW_43.jpg",
            "../images/Prairie Rambler/092016_2508_14StW_45.jpg",
            "../images/Prairie Rambler/092016_2508_14StW_50.jpg",
            "../images/Prairie Rambler/092016_2508_14StW_51.jpg",
            "../images/Prairie Rambler/092016_2508_14StW_55.jpg",
            "../images/Prairie Rambler/Home-12.jpg",
            "../images/Prairie Rambler/Home-13.jpg",
            "../images/Prairie Rambler/Home-16.jpg",
            "../images/Prairie Rambler/Home-18.jpg",
            "../images/Prairie Rambler/Home-19.jpg",
            "../images/Prairie Rambler/Home-22.jpg"
            ]
        },
        UPH2274: {
            titleImage: {
                src: "../images/Dakota 2 story/042916_2618_8thW_03.jpg",
                alt: "Dakota Two Story, front view"
            },
            wideImage: {
                src: "../images/Dakota 2 story/042916_2618_8thW_03.jpg",
                alt: "Dakota Two Story, front view"
            },
            title: "Dakota Two Story",
            description: "This 2532 sq. ft. two story is perfect for a .... featuring a ... and unique details. As with all Urban Prairie Homes it can be customized to your specifications so that you are truly able to build your dream home!",
            glance:
                {
                    squareFeet: "2532",
                    bedrooms: "5",
                    bedroomsAbr: "5",
                    bathrooms: "4",
                    garage: "3 stall",
                    basement: "Finished",
                WalkthroughVideo: "Call For Details"
                }
            ,
            floorPlanImages: [
                "../images/Dakota 2 story main floor.PNG",
                "../images/Upstairs dakota 2 story.PNG",
                "../images/Dakota 2 story basement.PNG"
            ],
            imageGallery: [
                "../images/Dakota 2 story/102915_2024_17.jpg",
                "../images/Dakota 2 story/042916_2618_8thW_24.jpg",
                "../images/Dakota 2 story/042916_2618_8thW_27.jpg",
                "../images/Dakota 2 story/042916_2618_8thW_28.jpg",
                "../images/Dakota 2 story/042916_2618_8thW_48.jpg",
                "../images/Dakota 2 story/042916_2618_8thW_61.jpg",
                "../images/Dakota 2 story/102915_2024_12.jpg"
            ]
        },
        UPH3254: {
            titleImage: {
                src: "../images/Meadow 2 story/4974-39-12-ave-n_6 (1).jpg",
                alt: "Meadow Two Story, front view"
            },
            wideImage: {
                src: "../images/Meadow 2 story/4974-39-12-ave-n_6 (1).jpg",
                alt: "Meadow Two Story, front view"
            },
            title: "Meadow Two Story",
            description: "This 3003 sq. ft. two story is perfect for a .... featuring a ... and unique details. As with all Urban Prairie Homes it can be customized to your specifications so that you are truly able to build your dream home!",
            glance:
                {
                    squareFeet: "3003",
                    bedrooms: "6 with the option of 7",
                    bedroomsAbr: "6-7",
                    bathrooms: "4",
                    garage: "3 stall",
                basement: "Finished",
                WalkthroughVideo: "Call For Details"
                }
            ,
            floorPlanImages: [
                "../images/Meadow 2 story Main floor.PNG",
                "../images/meadow 2 story upstairs.PNG",
                "../images/meadow 2 story basement.PNG"
            ],
            imageGallery: [
                "../images/Meadow 2 story/DSC_8340.jpg",
                "../images/Meadow 2 story/DSC_8395.jpg",
                "../images/Meadow 2 story/DSC_8415.jpg",
                "../images/Meadow 2 story/DSC_8476.jpg",
                "../images/Meadow 2 story/DSC_8506.jpg",
                "../images/Meadow 2 story/DSC_8516.jpg"
            ]
        },
        UPH2660: {
            titleImage: {
                src: "../images/Prairie 2 story/042717_6109Marigold_01.jpg",
                alt: "Prairie Two Story, front view"
            },
            wideImage: {
                src: "../images/Prairie 2 story/042717_6109Marigold_01.jpg",
                alt: "Prairie Two Story, front view"
            },
            title: "Prairie Two Story",
            description: "This 3118 sq. ft. two story is perfect for a .... featuring a ... and unique details. As with all Urban Prairie Homes it can be customized to your specifications so that you are truly able to build your dream home!",
            glance:
            {
                squareFeet: "3118",
                bedrooms: "6 with option for 7",
                bedroomsAbr: "6-7",
                bathrooms: "4",
                garage: "3 stall",
                basement: "Finished",
                WalkthroughVideo: "Call For Details"
            }
            ,
            floorPlanImages: [
                "../images/Prarie 2 story main floor.PNG",
                "../images/prairie 2 story upper floor.PNG",
                "../images/prairie 2 story basement.PNG"
            ],
            imageGallery: [
                "../images/Prairie 2 story/042717_6109Marigold_06.jpg",
                "../images/Prairie 2 story/042717_6109Marigold_19.jpg",
                "../images/Prairie 2 story/042717_6109Marigold_21.jpg",
                "../images/Prairie 2 story/042717_6109Marigold_26.jpg",
                "../images/Prairie 2 story/042717_6109Marigold_40.jpg",
                "../images/Prairie 2 story/042717_6109Marigold_43.jpg",
                "../images/Prairie 2 story/102915_180_33AveW_07.jpg",
                "../images/Prairie 2 story/102915_180_33AveW_12.jpg",
                "../images/Prairie 2 story/102915_180_33AveW_19.jpg",
                "../images/Prairie 2 story/102915_180_33AveW_25.jpg"
            ]
        },
        UPH3Level: {
            titleImage: {
                src: "../images/Aspen Rambler/061616_5674_43rdStS_01.jpg",
                alt: "Aspen Slab on Grade, front view"
            },
            wideImage: {
                src: "../images/Aspen Rambler/061616_5674_43rdStS_01.jpg",
                alt: "Aspen Slab on Grade, front view"
            },
            title: "Aspen Slab on Grade",
            description: "This 2047 sq. ft. two-story is perfect for a .... featuring a ... and unique details. As with all Urban Prairie Homes it can be customized to your specifications so that you are truly able to build your dream home!",
            glance:
                {
                    squareFeet: "2047",
                    bedrooms: "3 with option for 4",
                    bedroomsAbr: "3-4",
                    bathrooms: "2",
                    garage: "2 or 3 stall",
                    basement: "None",
                WalkthroughVideo: "Call For Details"
                }
            ,
            floorPlanImages: [
                "../images/aspen sog main floor.PNG"
            ],
            imageGallery: [
                "../images/Aspen Rambler/20_223_47Ave_W_21.jpg",
                "../images/Aspen Rambler/20_223_47Ave_W_06.jpg",
                "../images/Aspen Rambler/061616_5674_43rdStS_24.jpg",
                "../images/Aspen Rambler/DSC06188.jpg",
                "../images/Aspen Rambler/IMG_1393.JPG"
            ]
        },
        BluestemSOG: {
            titleImage: {
                src: "../images/Bluestem SOG/1jT0n5NU.jpg",
                alt: "Bluestem Slab on Grade, front view"
            },
            wideImage: {
                src: "../images/Bluestem SOG/1jT0n5NU.jpg",
                alt: "Bluestem Slab on Grade, front view"
            },
            title: "Bluestem Slab on Grade",
            description: "This 1862 sq. ft. two-story is perfect for a .... featuring a ... and unique details. As with all Urban Prairie Homes it can be customized to your specifications so that you are truly able to build your dream home!",
            glance:
            {
                squareFeet: "1862",
                bedrooms: "3",
                bedroomsAbr: "3",
                bathrooms: "2",
                garage: "2 or 3 stall",
                basement: "None",
                WalkthroughVideo: "Call For Details"
            }
            ,
            floorPlanImages: [
                "../images/Bluestem SOG main floor.PNG"
            ],
            imageGallery: [
                "../images/Bluestem SOG/4884_Blue_Bell_Loop__6.jpg",
                "../images/Bluestem SOG/4884_Blue_Bell_Loop__7.jpg",
                "../images/Bluestem SOG/4884_Blue_Bell_Loop__18.jpg"
            ]
        },
        UPH2019Parade: {
            titleImage: {
                src: "../images/Meadow SOG/DSC_7989.JPG",
                alt: "Current Model Home Meadow slab on grad, front elevation rendering"
            },
            wideImage: {
                src: "../images/Meadow SOG/DSC_7989.JPG",
                alt: "Current Model Home Meadow slab on grad, front elevation rendering"
            },
            title: "Meadow slab on grade",
            description: "This Meadow slab on grade Model is perfect for a .... featuring a ... and unique details. As with all Urban Prairie Homes it can be customized to your specifications so that you are truly able to build your dream home!",
            glance:
            {

                squareFeet: "1559" ,
                bedrooms: "2 with option for 3",
                bedroomsAbr: "2-3",
                bathrooms: "2", 
                garage: "2 stall",
                basement: "None, slab on grade",
                WalkthroughVideo: <a href="https://my.matterport.com/show/?m=ryEavSkHQsQ&mls=1">Meadow Slab On Grade Walkthrough</a>
            }
            ,
            floorPlanImages: [
                "../images/Meadow SOG Main floor.PNG"
            ],
            imageGallery: [               
                "../images/Meadow SOG/DSC_7792.jpg",
                "../images/Meadow SOG/DSC_7807.jpg",
                "../images/Meadow SOG/DSC_7817.jpg",
                "../images/Meadow SOG/DSC_7852.jpg",
                "../images/Meadow SOG/DSC_7862.jpg",
                "../images/Meadow SOG/DSC_7927.jpg",
                "../images/Meadow SOG/DSC_7932.jpg",
                "../images/Meadow SOG/DSC_7937.jpg",
                "../images/Meadow SOG/Home-8.jpg",
                "../images/Meadow SOG/Home-9.jpg",
                "../images/Meadow SOG/Home-10.jpg",
                "../images/Meadow SOG/Home-12.jpg"
            ]
        },
        PrairieSOG: {
            titleImage: {
                src: "../images/Prairie SOG/4801 Edited.jpg",
                alt: "Prairie Slab on Grade, front view"
            },
            wideImage: {
                src: "../images/Prairie SOG/4801 Edited.jpg",
                alt: "Prairie Slab on Grade, front view"
            },
            title: "Prairie Slab on Grade",
            description: "This 2008 sq. ft. two-story is perfect for a .... featuring a ... and unique details. As with all Urban Prairie Homes it can be customized to your specifications so that you are truly able to build your dream home!",
            glance:
            {
                squareFeet: "2008",
                bedrooms: "2 with the option of 3",
                bedroomsAbr: "2-3",
                bathrooms: "2",
                garage: "2 stall",
                basement: "None",
                WalkthroughVideo: "Call For Details"
            }
            ,
            floorPlanImages: [
                "../images/Prarie SOG main floor.PNG"
            ],
            imageGallery: [
                "../images/Prairie SOG/4801 Bluebell Loop S Fargo, ND7.jpg",
                "../images/Prairie SOG/4801 Bluebell Loop S Fargo, ND8.jpg",
                "../images/Prairie SOG/4801 Bluebell Loop S Fargo, ND10.jpg",
                "../images/Prairie SOG/4801 Bluebell Loop S Fargo, ND14.jpg",
                "../images/Prairie SOG/4801 Bluebell Loop S Fargo, ND19.jpg",
                "../images/Prairie SOG/4801 Bluebell Loop S Fargo, ND20.jpg",
                "../images/Prairie SOG/4801 Bluebell Loop S Fargo, ND34.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_1.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_3.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_4.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_5.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_6.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_11.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_16.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_18.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_20.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_21.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_22.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_24.jpg",
                "../images/Prairie SOG/4864 Bluebell Loop S (Staged)_27.jpg"
            ]
        },
        PrairieSOGw: {
            titleImage: {
                src: "../images/Prairie SOG w Loft/4864 Bluebell Loop S (Staged)_34.jpg",
                alt: "Prairie Slab on Grade with loft, front view"
            },
            wideImage: {
                src: "../images/Prairie SOG w Loft/4864 Bluebell Loop S (Staged)_34.jpg",
                alt: "Prairie Slab on Grade with loft, front view"
            },
            title: "Prairie Slab on Grade with loft",
            description: "This 2482 sq. ft. two-story is perfect for a .... featuring a ... and unique details. As with all Urban Prairie Homes it can be customized to your specifications so that you are truly able to build your dream home!",
            glance:
            {
                squareFeet: "2482",
                bedrooms: "2 with the option of 4",
                bedroomsAbr: "2-4",
                bathrooms: "2",
                garage: "2 stall",
                basement: "None",
                WalkthroughVideo: "Call For Details"
            }
            ,
            floorPlanImages: [
                "../images/Prairie SOG With loft main floor.PNG",
                "../images/Praririe SOG With Loft Upper.PNG"
            ],
            imageGallery: [
                "../images/Prairie SOG w Loft/4864 Bluebell Loop S (Staged)_4.jpg",
                "../images/Prairie SOG w Loft/4864 Bluebell Loop S (Staged)_9.jpg",
                "../images/Prairie SOG w Loft/4864 Bluebell Loop S (Staged)_10.jpg",
                "../images/Prairie SOG w Loft/4864 Bluebell Loop S (Staged)_16.jpg",
                "../images/Prairie SOG w Loft/4864 Bluebell Loop S (Staged)_18.jpg",
                "../images/Prairie SOG w Loft/4864 Bluebell Loop S (Staged)_20.jpg",
                "../images/Prairie SOG w Loft/4864 Bluebell Loop S (Staged)_22.jpg",
                "../images/Prairie SOG w Loft/4864 Bluebell Loop S (Staged)_24.jpg",
                "../images/Prairie SOG w Loft/4864 Bluebell Loop S (Staged)_27.jpg",
                "../images/Prairie SOG w Loft/4864 Bluebell Loop S (Staged)_31.jpg",
                "../images/Prairie SOG w Loft/4864 Bluebell Loop S (Staged)_37.jpg",
                "../images/Prairie SOG w Loft/4801 Bluebell Loop S Fargo, ND7.jpg",
                "../images/Prairie SOG w Loft/4801 Bluebell Loop S Fargo, ND8.jpg",
                "../images/Prairie SOG w Loft/4801 Bluebell Loop S Fargo, ND10.jpg",
                "../images/Prairie SOG w Loft/4801 Bluebell Loop S Fargo, ND11.jpg",
                "../images/Prairie SOG w Loft/4801 Bluebell Loop S Fargo, ND12.jpg",
                "../images/Prairie SOG w Loft/4801 Bluebell Loop S Fargo, ND13.jpg",
                "../images/Prairie SOG w Loft/4801 Bluebell Loop S Fargo, ND29.jpg",
                "../images/Prairie SOG w Loft/4801 Bluebell Loop S Fargo, ND34.jpg"
            ]
        },

        DakotaSOG: {
            titleImage: {
                src: "../images/Dakota SOG w Loft/PWMb6Uuk.jpg",
                alt: "Dakota Slab on Grade with loft, front view"
            },
            wideImage: {
                src: "../images/Dakota SOG w Loft/PWMb6Uuk.jpg",
                alt: "Dakota Slab on Grade with loft, front view"
            },
            title: "Dakota Slab on Grade with loft",
            description: "This 2448 sq. ft. two-story is perfect for a .... featuring a ... and unique details. As with all Urban Prairie Homes it can be customized to your specifications so that you are truly able to build your dream home!",
            glance:
            {
                squareFeet: "2448",
                bedrooms: "2 with the option of 4",
                bedroomsAbr: "2-4",
                bathrooms: "2",
                garage: "2-3 stall",
                basement: "None",
                WalkthroughVideo: "Call For Details"
            }
            ,
            floorPlanImages: [
                "../images/Dakota SOB with loft main floor.PNG",
                "../images/Dakota SOB with loft upper.PNG"
            ],
            imageGallery: [
                "../images/Dakota SOG w Loft/4864 Bluebell Loop S (Staged)_16.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__1.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__3.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__4.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__5.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__6.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__7.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__9.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__10.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__11.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__12.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__13.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__14.jpg",
                "../images/Dakota SOG w Loft/4884_Blue_Bell_Loop__18.jpg"
            ]
        }
    };
    planGroupings = [
        {
            name: "",
            plans: [

            ]
        },
        {
            name: "Rambler",
            plans: [
                {
                    path: "UPH1268",
                    plan: this.plans.UPH1268
                },
                {
                    path: "UPH1295",
                    plan: this.plans.UPH1295
                },

                {
                    path: "UPH2028",
                    plan: this.plans.UPH2028
                },
                {
                    path: "UPHSlab",
                    plan: this.plans.UPHSlab
                }
            ]
        },
        {
            name: "Two Story",
            plans: [
                {
                    path: "UPH2274",
                    plan: this.plans.UPH2274
                },
                {
                    path: "UPH3254",
                    plan: this.plans.UPH3254
                },
                {
                    path: "UPH2660",
                    plan: this.plans.UPH2660
                }
            ]
        },
        {
            name: "One level/Slab on grade",
            plans: [
                {
                    path: "BluestemSOG",
                    plan: this.plans.BluestemSOG
                },
                {
                    path: "UPH2019Parade",
                    plan: this.plans.UPH2019Parade
                },
                {
                    path: "PrairieSOG",
                    plan: this.plans.PrairieSOG
                },
                {
                    path: "PrairieSOGw",
                    plan: this.plans.PrairieSOGw
                },
                {
                    path: "DakotaSOG",
                    plan: this.plans.DakotaSOG
                }
            ]
        }
    ]
}

export default FloorPlan;
